import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import {
  configurations,
  logout,
  plugAec,
  talentsAec,
} from "../../assets/icons/icons";

import {
  Body,
  Card,
  CardsItens,
  Icon,
  IconLink,
  LabelItem,
  LabelLogout,
  LabelUser,
  RowItens,
  talentsModule,
} from "./HomeStyle";

import * as urls from "../../utils/contants/urls";

const Home = () => {
  const doLogout = () => {
    localStorage.clear();
    window.location.href = "\\";
  };
  return (
    <Body>
      <div className="container">
        <RowItens>
          <LabelUser>
            <FormattedMessage id="homeOptions.welcome" />
          </LabelUser>
          <Link onClick={() => doLogout()}>
            <LabelLogout>
              <FormattedMessage id="homeOptions.logout" />
              <IconLink>{logout()}</IconLink>
            </LabelLogout>
          </Link>
        </RowItens>
        <div className="row">
          <div className="col-12 ">
            <CardsItens>
              {localStorage.getItem("podeAcessarTalentos") == "true" &&
                localStorage.getItem("tipoPerfilUsuario") == 1 && (
                  <Card borderRadiusLeft>
                    <Link to={urls.TALENTS_AEC}>
                      <Icon>{talentsAec()}</Icon>
                      <LabelItem>
                        <FormattedMessage id="homeOptions.talentsModule" />
                      </LabelItem>
                    </Link>
                  </Card>
                )}
              {localStorage.getItem("podeAcessarPlug") == "true" && (
                <Card>
                  <Link to={urls.PLUG_AEC}>
                    <Icon>{plugAec()}</Icon>
                    <LabelItem>
                      <FormattedMessage id="homeOptions.choiceModule" />
                    </LabelItem>
                  </Link>
                </Card>
              )}
              {localStorage.getItem("podeeditarperfil") == "true" && (
                <Card borderRadiusRight>
                  <Link to={urls.MANAGER_AEC}>
                    <Icon>{configurations()}</Icon>
                    <LabelItem>
                      <FormattedMessage id="homeOptions.managerModule" />
                    </LabelItem>
                  </Link>
                </Card>
              )}
            </CardsItens>
          </div>
        </div>
      </div>
    </Body>
  );
};

export default Home;
