import * as urls from "utils/contants/urls";
import theme from "../../../../../components/shared/Theme.js";
import {
  Label,
  LabelHeader,
  Table,
  TableBody,
  TableColumn,
  TableHead,
  TableRow,
} from "components/shared/CustomComponentsStyle";
import React, { useEffect, useState, useRef } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { Span} from "./ManagerCandidatesBenefitsBulkStyle.js";
import FormGroup from "../../../../../components/Forms/group/FormGroup";
import {
  SelectFieldInput,
  ButtonDefault,
  MultSelectCreatable,
  TextField,
  ButtonSecondary,
  DatePicker,
} from "components/shared/CustomInputs";
import { FormattedMessage } from "react-intl";
import Load from "components/Layout/Load";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as XLSX from "xlsx";
import { Creators as userBenefits } from "store/ducks/userBenefits";

const ManagerCandidatesBenefitsBulk = ({
  filesCandidateSelected,
  isLoading,
  location,
  messagePublication,
  requestTypeContracts,
  redirectToContracts,
  inviteCandidates,
  changeContractRequest,
  kitsContratosList,
  log,
  requestAdtive,
  adtiveTypesList,
  createUserBenefits,
  createBenefitsBulk,
  listaDeImportacao,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    isLoading = false;
  }, []);


  const fileInputRef = useRef(null);
  useEffect(() => {
  }, [listaDeImportacao])

  const importarArquivo = (file) => {
    try {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);
        createBenefitsBulk(json)
      };
      
      reader.readAsArrayBuffer(file);
    } catch (error) {
    }
  };

  const saveImport = () => {
    if (selectedFile) {
      importarArquivo(selectedFile);
    }
  };


  const handleFileImport = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const renderStatus = (listaStatus) =>
    listaStatus.map((status) => (
      <TableRow key={status.cpf}>
        <TableColumn ta="center">
          <Label>{status.cpf}</Label>
        </TableColumn>
        <TableColumn ta="center">
          <Label>{status.detalhe}</Label>
        </TableColumn>
        <TableColumn ta="center">
          <Label>{status.status ? "Sucesso" : "Erro"}</Label>
        </TableColumn>
      </TableRow>
    ));

  const openFileDialog = () => {
    fileInputRef.current.click();
  };


 
  if (isLoading) return <Load isLoading={isLoading} />;
  return (
    <React.Fragment>
      <h3 style={{ color: theme.colors.defaultColor }}>Cadastrar Beneficios</h3>
        <FormGroup formName="Importações">
           {selectedFile && (
            <Span style={{ marginTop: "15px" }}>
              <p>Arquivo: {selectedFile.name}</p>
            </Span>
           )}
          <Row style={{paddingBottom:"25px", display:"flex", justifyContent:"center"}}>
            <Col md={2} style={{ marginTop: "15px" }}>
              <ButtonSecondary
                onClick={openFileDialog}
                name={<FormattedMessage id="sharedItems.import" />}
              />
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                accept=".xlsx, .xls, .csv"
                onChange={handleFileImport}
              />
            </Col>
            <Col md={2} style={{ marginTop: "15px" }}>
              <ButtonSecondary
                onClick={saveImport}
                name={<FormattedMessage id="sharedItems.saveImport" />}
              />
            </Col>
          </Row>
          <Table>
            <TableHead style ={{display: "contents"}}>
              <TableRow >
                <TableColumn ta="center" width="33%">
                  <LabelHeader>CPF</LabelHeader>
                </TableColumn>
                <TableColumn ta="center" width="33%">
                  <LabelHeader>DETALHE</LabelHeader>
                </TableColumn>
                <TableColumn ta="center" width="33%">
                  <LabelHeader>STATUS</LabelHeader>
                </TableColumn>
              </TableRow>
            </TableHead>
            <TableBody>
              {renderStatus(listaDeImportacao)}
            </TableBody>
          </Table>
        </FormGroup>
     
    </React.Fragment>
  );
};


const mapDispatchToProps = (dispatch) => ({
  createBenefitsBulk: (candidatesBulk) =>
    dispatch(userBenefits.candidatesCreateBenefitsBulkRequest(candidatesBulk)),
});


const mapStateToProps = (state) => ({
  isLoading: state.userBenefits.isLoading,
  listaDeImportacao: state.userBenefits.benefitsBulk,
});


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManagerCandidatesBenefitsBulk);

ManagerCandidatesBenefitsBulk.propTypes = {
  filesCandidateSelected: PropTypes.func,
};

ManagerCandidatesBenefitsBulk.defaultProps = {
  candidates: () => {},
  filesCandidateSelected: () => {},
};
