import {
  ActionItem,
  Actions,
  Label,
  LabelAction,
  LabelHeader,
  Table,
  TableBody,
  TableColumn,
  TableHead,
  TableRow,
} from "components/shared/CustomComponentsStyle";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { view } from "assets/icons/icons";
import { StatusDocuments } from "utils/contants/enums";
import BreadCrumb from "components/Layout/breadcrumb";
import * as urls from "utils/contants/urls";
import { push } from "connected-react-router";
import { history } from "utils/routes";
import {
  SelectFieldInput,
  CheckBox,
  ButtonDefault,
  ButtonSecondary,
} from "components/shared/CustomInputs";

import { FormattedMessage } from "react-intl";
import Load from "components/Layout/Load";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Creators as documentsAction } from "store/ducks/documents";
import { Creators as vacancyCandidatesActions } from "store/ducks/candidates";

import { YesOrNoWithTextDialog } from "components/shared/CustomComponents";
import { Body } from "./ListDocumentsStyle";
import { returnForAnalysisAction } from "../../../../../locale";


const ListDocuments = ({
  match,
  candidateFiles,
  filesCandidateSelected,
  isLoading,
  location,
  setStatusFileRequest,
  confirmAnalisys,
  incluseNewDoc,
  redirectToDocuments,
  requestManagerCandidates,
  dowloadDocuments,
}) => {
  useEffect(() => {
    const { params } = match;
    if (params.id) {
      candidateFiles({ id: params.id });
    }
    history.push(`${urls.DOCUMENTS_LIST}/${params.id}`);
  }, []);
  const [showDialogYesOrNo, setShowDialogYesOrNo] = useState(0);
  const token = localStorage.getItem("Authorization");
  const [idVaga, setidVaga] = useState("Indefinido");
  const canShowDialog = (idDoc) => {
    return showDialogYesOrNo == idDoc;
  };

  const setColorStatus = (status) => {
    switch (status) {
      case 2:
        return { fontWeight: "bold", color: "#d01616" };
      case 3:
        return { fontWeight: "bold", color: "#32a34c" };
      case 7:
        return { fontWeight: "bold", color: "#D3C90B" };
      default:
        return {};
    }
  };

  const [fileAditional, setfileAditional] = useState();
  const [showReturnForAnalysis, setShowReturnForAnalysis] = useState(false);

  const [exibirReprovados, setExibirReprovados] = useState(false);

  const returnStageForAnalysis = (dataToRequest, feedback) => {
    const applyedAt = new Date(filesCandidateSelected.applyedAt).toLocaleString("pt-BR");
    const candidates = [
      {
        id: filesCandidateSelected.idCandidato,
        selected: true,
        code: "code",
        name: filesCandidateSelected.nome,
        currentStage: "Envio de Documentação DIGITAL",
        resultStatus: "-",
        result: "-",
        applyedAt,
        status: 2,
      },
    ];

    const enabledSendToCandidates = false;
    const kindOfAction = returnForAnalysisAction.id;

    requestManagerCandidates(
      candidates,
      kindOfAction,
      filesCandidateSelected.idVaga,
      enabledSendToCandidates,
      feedback,
      filesCandidateSelected.idEtapa,
    );
  };

  const baixarDocumentos = (idCandidatoEtapa) => {
    dowloadDocuments({
      id: idCandidatoEtapa
    })  
  };

  const renderFilesCandidatesTable = (
    candidateDocs,
    setStatusFile,
    token,
    canShowDialog,
    setShowDialogYesOrNo
  ) => {
    return (
      <div>
        {fileAditional}
        <Row>
          <Col md={2}>
            <label style={{ fontWeight: "bold", color: "#005394" }}>
              Nome:&nbsp;
            </label>
            <Label bold>{candidateDocs.nome}</Label>
          </Col>
          <Col md={2}>
            <label style={{ fontWeight: "bold", color: "#005394" }}>
              CPF:&nbsp;
            </label>
            <Label bold>{candidateDocs.cpf}</Label>
          </Col>
          <Col md={3}>
            <label style={{ fontWeight: "bold", color: "#005394" }}>
              Status Etapa:&nbsp;
            </label>
            <Label bold>{candidateDocs.statusEtapa}</Label>
          </Col>
          <Col md={3}>
            <SelectFieldInput
              label={"Incluir documento adicional"}
              items={candidateDocs.filesBaseList.map((e) => ({
                nome: e.nome,
                id: e.Id,
              }))}
              value={fileAditional}
              onChange={(e) => {
                setfileAditional(e.target.value);
              }}
              disabled={!candidateDocs.podeEditar}
            />
          </Col>
          <Col md={2} style={{ marginBottom: "-18px" }}>
            <CheckBox
              onChange={() => setExibirReprovados(!exibirReprovados)}
              checked={exibirReprovados}
              label={"Exibir reprovados"}
            />
          </Col>
          <Col md={2} style={{ marginBottom: "-17px" }}>
            <YesOrNoWithTextDialog
              cancelAction={setfileAditional}
              show={fileAditional != null}
              showMessage={true}
              question={"Informe as instruções para o colaborador."}
              confirmAction={confirmNewDocCandidate}
              idConfirm={{
                fileId: fileAditional,
                IdCandidatoNaEtapa: candidateDocs.idCandidatoEtapa,
              }}
            />
          </Col>
        </Row>

        <Table>
          <TableBody>
            <TableRow noBorder>
              <TableHead style={{ width: "5px" }}>
                <LabelHeader>
                  <FormattedMessage id="documentsCandidateList.columns.idDocument" />
                </LabelHeader>
              </TableHead>
              <TableHead ta="left" >
                <LabelHeader style={{ marginLeft: '30px'}} >
                  <FormattedMessage id="documentsCandidateList.columns.typeDocument" />
                </LabelHeader>
              </TableHead>
              <TableHead ta="left">
                <LabelHeader  style={{ marginLeft: '100px'}}>
                  <FormattedMessage id="documentsCandidateList.columns.document" />
                </LabelHeader>
              </TableHead>
              <TableHead ta="left">
                <LabelHeader>Instruções</LabelHeader>
              </TableHead>
            </TableRow>
            {candidateDocs.filesList &&
              renderTableFilesItems(
                candidateDocs,
                setStatusFile,
                token,
                candidateDocs.podeEditar,
                canShowDialog,
                setShowDialogYesOrNo
              )}
          </TableBody>
        </Table>
      </div>
    );
  };

  const renderTableFilesItems = (
    candidates,
    setStatusFile,
    token,
    podeEditar,
    canShowDialog,
    setShowDialogYesOrNo
  ) =>
    candidates.filesList.map((file) => (
      
      <TableRow
        style={!exibirReprovados && file.status == 5 ? { display: "none" } : {}}
      >
        {
           console.log(file)
        }
        <TableColumn>
          <Label>{file.Id}</Label>
        </TableColumn>
        <TableColumn ta="left">
          <Label style={{ marginLeft: '30px'}}>{file.type}</Label>
        </TableColumn>
        <TableColumn ta="left" >
          <Label style={{ marginLeft: '100px'}}>{file.nome}</Label> 
        </TableColumn>
        <TableColumn ta="left">
          <Label>{file.instrucao}</Label>
        </TableColumn>
        <TableColumn ta="right" action>
          {file.url && (
            <Actions
              flexPosition="flex-start"
              onClick={() =>
                window.open(file.url + "?Authorization=" + token, "_blank")
              }
              style={{ cursor: "progress" }}
            >
              <ActionItem>
                {view()}
                <LabelAction>
                  <FormattedMessage id="sharedItems.view" />
                </LabelAction>
              </ActionItem>
            </Actions>
          )}
        </TableColumn>

        <TableColumn
          style={file.status == 5 ? { display: "none" } : {}}
          ta="right"
          action
        >
          <SelectFieldInput
            disabled={!podeEditar || file.status == 5}
            items={StatusDocuments}
            value={file.status == 7 ? 4 : file.status}
            onChange={(e) => {
              setStatusFile({
                fileId: file.Id,
                status: e.target.value,
                IdCandidatoNaEtapa: candidates.idCandidatoEtapa,
              });
              setShowDialogYesOrNo(file.Id);
            }}
            style={setColorStatus(file.status)}
          />
        </TableColumn>
        <TableColumn
          style={file.status == 5 ? { color: "#adb5bd" } : { display: "none" }}
          ta="center"
          action
        >
          <Label bold>Reprovado</Label>
        </TableColumn>
        <YesOrNoWithTextDialog
          cancelAction={setShowDialogYesOrNo}
          show={canShowDialog(file.Id)}
          showMessage={true}
          question={"Informe as instruções para o colaborador."}
          confirmAction={setStatusFile}
          idConfirm={{
            fileId: file.Id,
            status: "2",
            IdCandidatoNaEtapa: candidates.idCandidatoEtapa,
          }}
        />
      </TableRow>
    ));

  const confirmaAnaliseDocumentos = (idcandidateEtapa) => {
    confirmAnalisys({ id: idcandidateEtapa });
  };

  const setStatusFile = (fileData, message) => {
    if (fileData.status == "2" && (message == undefined || message == "")) {
      setShowDialogYesOrNo(fileData.fileId);
    } else {
      setStatusFileRequest({
        idDocumento: fileData.fileId,
        idCandidatoEmEtapa: fileData.IdCandidatoNaEtapa,
        status: fileData.status,
        message: message,
      });
    }
  };

  const confirmNewDocCandidate = (fileData, message) => {
    incluseNewDoc({
      idDocumentoBase: fileData.fileId,
      idCandidatoEmEtapa: fileData.IdCandidatoNaEtapa,
      message: message,
    });
    setfileAditional();
  };

  if (isLoading) return <Load isLoading={isLoading} />;

  return (
    <React.Fragment>
      <BreadCrumb
        location={location}
        title={"Vaga: " + filesCandidateSelected.idVaga}
      />
      <Body>
        <Row>
          <Col>
            <ButtonDefault
              onClick={() => redirectToDocuments()}
              width="230px"
              mr="10px"
              name={"Voltar"}
            />
          </Col>
          {filesCandidateSelected.statusEtapa === "Aprovado" && (
            <Col>
              <ButtonSecondary
                onClick={() => setShowReturnForAnalysis(true)}
                width="230px"
                mr="10px"
                name={"Retornar para Análise"}
                style={{ float: "right" }}
              />
            </Col>
          )}
        </Row>
        {filesCandidateSelected.filesBaseList &&
          renderFilesCandidatesTable(
            filesCandidateSelected,
            setStatusFile,
            token,
            canShowDialog,
            setShowDialogYesOrNo
          )}
          
        <ButtonSecondary
          onClick={() =>
            confirmaAnaliseDocumentos(filesCandidateSelected.idCandidatoEtapa)
          }
          width="133px"
          mr="18px"
          name={"Confirmar"}
          disabled={!filesCandidateSelected.podeConfirmar}
        />
        {filesCandidateSelected.statusEtapa === "Aprovado" && (
          <Row>
            <ButtonDefault
              name={
                "Baixar todos os documentos"
              }
              onClick={() =>
                baixarDocumentos(filesCandidateSelected.idCandidatoEtapa)
              }
              style={{marginLeft:"80%", display:"block", justifyContent:"center", alignItems:"center", backgroundColor: "green" ,width: "270px"}}
            />
          </Row>
        )}
      </Body>
      <YesOrNoWithTextDialog
        cancelAction={setShowReturnForAnalysis}
        show={showReturnForAnalysis}
        showMessage
        question={"Deseja retornar a etapa para Análise?"}
        confirmAction={returnStageForAnalysis}
        idConfirm={{
          vacancyId: filesCandidateSelected.idVaga,
          IdCandidatoNaEtapa: filesCandidateSelected.idCandidatoEtapa,
          stageId: filesCandidateSelected.idEtapa,
        }}
      />
    </React.Fragment>
  );
};
const mapDispatchToProps = (dispatch) => ({
  candidateFiles: (id) => dispatch(documentsAction.candidateFilesRequest(id)),
  dowloadDocuments: (payload) =>
    dispatch(documentsAction.dowloadDocumentsFilesRequest(payload)),
  setStatusFileRequest: (payload) =>
    dispatch(documentsAction.candidateSetStatusFileRequest(payload)),
  confirmAnalisys: (payload) =>
    dispatch(documentsAction.candidateSetStatusStageRequest(payload)),
  incluseNewDoc: (payload) =>
    dispatch(documentsAction.candidateIncludeNewDocRequest(payload)),
  redirectToDocuments: (id) => dispatch(push(`${urls.DOCUMENTS_CANDIDATES}`)),
  requestManagerCandidates: (
    candidates,
    kindOfAction,
    vancancyId,
    enabledSendToCandidates,
    feedback,
    stageId
  ) =>
    dispatch(
      vacancyCandidatesActions.candidatesDispatchManagerRequest(
        candidates,
        kindOfAction,
        vancancyId,
        enabledSendToCandidates,
        feedback,
        stageId
      )
    ),
});
const mapStateToProps = (state) => ({
  isLoading: state.documents.isLoading,
  filesCandidateSelected: state.documents.candidatesFiles,
});

export default connect(mapStateToProps, mapDispatchToProps)(ListDocuments);

ListDocuments.propTypes = {
  filesCandidateSelected: PropTypes.func,
};

ListDocuments.defaultProps = {
  filesCandidateSelected: () => {},
};
