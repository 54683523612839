import React, { useState, useRef, useEffect } from "react";

import { dashboardUrls } from "../../../../utils/contants/urlsApi";

import {
  MainFrameDash,
  NotificationArea,
  Header,
  Nav,
  ButtonNav,
} from "./DashBoardPlugMainStyle";

import DynamicDash from "../../../../components/DynamicDash";

import { connect } from "react-redux";

import theme from "../../../../components/shared/Theme";

import { Button } from "../../../../components/shared/CustomInputs";

const DashBoardPlugMain = (props) => {
  const name = window.localStorage.getItem("name").split(" ")[0];

  let colorstemp = [];
  colorstemp.length = 100;
  colorstemp.fill(0);
  colorstemp = colorstemp.map((item) => {
    const color = "0123456789ABCDEF"
      .split("")
      .sort(() => Math.random() - 0.5)
      .join("")
      .substring(0, 6);
    const rand = (frm, to) => {
      return ~~(Math.random() * (to - frm)) + frm;
    };
    return `rgba(${rand(0, 255)}, ${rand(0, 255)}, ${rand(0, 255)}, 0.3)`;
  });

  const defaultColors = useRef();
  defaultColors.current = defaultColors.current
    ? defaultColors.current
    : colorstemp;

  const colors = defaultColors.current;

  const [view, setView] = useState(0);
  const [dashBase, setDashBase] = useState(null);
  const [dashData, setDashData] = useState(null);
  const [filterList, setFilterList] = useState([]);
  const [notificationData, setNotificationData] = useState();

  const tabFilter = useRef(0);
  tabFilter.current = tabFilter.current === 0 ? 0 : tabFilter.current;

  const tabsView = useRef([
    {
      label: "Documentação",
      data: [
        {
          id: new Date().getTime(),
          type: "iconcard",
          title: "",
          style: {
            column: `merge-0`,
            row: "merge-row-0",
          },
          customStyle: {
            color: "var(--red)",
            icon: "ic_schedule_send",
          },
          data: "-",
          attribute: "totalCandidatosAdmitidos",
          value: "totalRegistros",
          titleValue: "nome",
        },
        {
          id: new Date().getTime(),
          type: "iconcard",
          title: "",
          style: {
            column: `merge-0`,
            row: "merge-row-0",
          },
          customStyle: {
            color: "var(--green)",
            icon: "ic_file_download_done",
          },
          data: "-",
          attribute: "totalCandidatosDesistentes",
          value: "totalRegistros",
          titleValue: "nome",
        },
        {
          id: new Date().getTime(),
          type: "iconcard",
          title: "",
          style: {
            column: `merge-0`,
            row: "merge-row-0",
          },
          customStyle: {
            color: "var(--yellow)",
            icon: "ic_folder",
          },
          data: "-",
          attribute: "totalDocumentosPendenteDeEnvio",
          value: "totalRegistros",
          titleValue: "nome",
        },
        {
          id: new Date().getTime(),
          type: "chartcard",
          title: "Status por Casa",
          style: {
            column: `merge-2`,
            row: "merge-row-0",
          },
          customStyle: {
            color: theme.colors.defaultColor,
            height: "auto",
          },
          data: "",
          attribute: "totalCandidatosPorSiteStatus",
          value: "totalRegistros",
          titleValue: "nome",
          labelsValues: [
            "totalCandidatosAdmitidos",
            "totalCandidatosDesistentes",
            "totalDocumentosPendenteDeEnvio",
          ],
        },
      ],
    },
  ]);

  const alterView = (_data) => {
    setView(_data);
  };

  const getDataDash = (_json) => {
    if (!_json) {
      setDashData({
        data: [],
        base: _json,
        date: _json?.dateTimeExpiration ? _json.dateTimeExpiration : null,
        lastupdate: _json?.tempoRestanteCacheMinutos
          ? ~~_json?.tempoRestanteCacheMinutos
          : null,
      });
      return [];
    }

    const json =
      filterList?.length > 0
        ? _json[filterList[tabFilter.current]?.value]
        : _json;

    let result = tabsView.current[~~view].data;

    if (!result) {
      return [];
    }

    let objStatus = {};

    result = result.map((item) => {
      const objBase = (json[item?.attribute] || {})[0];
      if (objBase) {
        if (/chart/gi.test(item.type)) {
           
          let list = item;

          item.data = {
            type: "bar",
            data: {
              labels: item?.labelsValues?.map(
                (n, idx) => json[n][0]?.nome?.toString() || `Campo - ${idx + 1}`
              ),
              datasets: [
                {
                  label: "item",
                  data: item?.labelsValues?.map(
                    (n, idx) => json[n][0]?.totalRegistros?.toString() || 0
                  ),
                  backgroundColor: [
                    "rgb(220 53 69)",
                    "rgb(40 167 69)",
                    "rgb(255 193 7)",
                    "rgb(253 126 20)",
                    "rgb(255 193 7)",
                    "rgba(153, 102, 255, 0.7)",
                    "rgba(201, 203, 207, 0.7)",
                  ],
                },
              ],
            },
            options: {
              indexAxis: "x",
              responsive: true,
              hoverOffset: 1,
              maintainAspectRatio: true,
              aspectRatio: 3.6,
              scales: {
                x: {
                  beginAtZero: true,
                  stacked: true,
                  grid: {
                    offset: true,
                  },
                },
                y: {
                  stacked: true,
                  beginAtZero: true, // Comece o eixo Y a partir de zero
                  suggestedMax: 100, // Defina um valor máximo sugerido
                  grid: {
                    offset: false,
                  },
                },
              },
              plugins: {
                datalabels: {
                  color: "#000",
                  anchor: "center",
                  offset: -20,
                  align: "left",
                  display: false,
                },
                legend: {
                  display: false,
                },
              },
            },
          };
          
        } else {
          item.data = objBase[item?.value]?.toString();
          item.title = objBase[item?.titleValue];
        }
      }
      return item;
    });

    setDashData({
      data: result,
      base: _json,
      date: _json?.dateTimeExpiration ? _json.dateTimeExpiration : null,
      lastupdate: _json?.tempoRestanteCacheMinutos
        ? ~~_json?.tempoRestanteCacheMinutos
        : null,
    });
  };

  const dataDashGetData = async () => {
    const url = [dashboardUrls.documents, dashboardUrls.contracts][~~view];

    const headers = new Headers();
    headers.append(
      "Authorization",
      `Basic ${window.localStorage.getItem("Authorization")}`
    );

    const params = {
      method: "GET",
      headers: headers,
    };

    let _data;
    let json;
    try {
      //throw true;
      _data = await fetch(url, params);
      json = await _data.json();
    } catch (e) {
      if (~~view === 0) {
        json = {
          tempoRestanteCacheMinutos: 1439.99998833,
          dateTimeExpiration: "2021-12-21T15:45:50.1270704-03:00",
        };
      }

      json = null;
    }

    if (json) {
      let dataFilter = Object.keys(json).filter((item) =>
        /(mes|trimestre|semestre|ano)/.test(item)
      );
      const map_filter = dataFilter.map((item) => ({
        label: json[item].tipoPeriodo,
        value: item,
      }));
      setFilterList(map_filter);
      setDashBase(json);
    } else {
      setDashData({
        data: [],
        base: [],
        date: json?.dateTimeExpiration ? json.dateTimeExpiration : null,
        lastupdate: json?.tempoRestanteCacheMinutos
          ? ~~json?.tempoRestanteCacheMinutos
          : null,
      });
    }
  };

  const alterFilterTab = (_data) => {
    tabFilter.current = _data.id;
    getDataDash(dashData.base);
  };

  useEffect(() => {
    getDataDash(dashBase);
  }, [dashBase]);

  useEffect(() => {
    tabFilter.current = 0;
    setDashData(null);
    setFilterList([]);
    dataDashGetData();
  }, [view]);

  useEffect(() => {
    dataDashGetData();
  }, []);

  return (
    <MainFrameDash key={`filter-${tabFilter.current}`}>
      <Header>
        Bem vindo, <strong>{name}</strong>
      </Header>
      <NotificationArea style={{ display: notificationData ? "flex" : "none" }}>
        {notificationData}
      </NotificationArea>

      <section className="area-section">
        <div className="filter">
          {filterList?.length > 0 ? (
            <div className="filter-title">
              <strong>Filtro</strong>:
            </div>
          ) : (
            ""
          )}
          {filterList.map((item, id) => {
            return (
              <>
                <Button
                  onClick={() => alterFilterTab({ ...item, id })}
                  type={~~tabFilter.current === id ? "" : "secondary"}
                  name={item.label}
                  width="143px"
                ></Button>
              </>
            );
          })}
        </div>
        <DynamicDash
          data={dashData?.data}
          key={`tab-${~~view}-${dashData?.lastupdate}`}
          date={dashData?.date}
          time={dashData?.lastupdate}
          onUpdateData={dataDashGetData}
        />
      </section>
    </MainFrameDash>
  );
};

export default connect()(DashBoardPlugMain);

