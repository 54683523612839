import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  getUserBenefits: ["payload"],
  getUserBenefitsSuccess: ["payload", "isLoading"],
  getUserBenefitsFail: ["error", "isLoading"],

  getUserBenefitsFiltered: ["filter"],
  getUserBenefitsFilteredSuccess: ["payload", "isLoading"],
  getUserBenefitsFilteredFail: ["error", "isLoading"],

  createUserBenefits: ["payload"],
  createUserBenefitsSuccess: ["payload", "isLoading"],
  createUserBenefitsFail: ["error", "isLoading"],

  getUserBenefitsById: ["payload"],
  getUserBenefitsByIdSuccess: ["payload", "isLoading"],
  getUserBenefitsByIdFail: ["error", "isLoading"],

  removeValeTransporteById: ["benefit"],
  removeValeTransporteByIdSuccess: ["payload", "isLoading"],
  removeValeTransporteByIdFail: ["error", "isLoading"],

  atualizarValeTransporteById: ["benefit"],
  atualizarValeTransporteByIdSuccess: ["payload", "isLoading"],
  atualizarValeTransporteByIdFail: ["error", "isLoading"],

  atualizarValeRefeicaoById: ["benefit"],
  atualizarValeRefeicaoByIdSuccess: ["payload", "isLoading"],
  atualizarValeRefeicaoByIdFail: ["error", "isLoading"],

  removerBeneficioById: ["benefit"],
  removerBeneficioByIdSuccess: ["payload", "isLoading"],
  removerBeneficioByIdFail: ["error", "isLoading"],

  removerValeRefeicao: ["payload"],
  removerValeRefeicaoSuccess: ["payload", "isLoading"],
  removerValeRefeicaoFail: ["error", "isLoading"],

  candidatesExportBenefitsInformationRequest: ["candidates", "vacancyId"],
  candidatesExportBenefitsInformationSuccess: ["payload"],
  candidatesExportBenefitsInformationFail: ["error"],

  candidatesCreateBenefitsBulkRequest: ["benefitsBulk"],
  candidatesCreateBenefitsBulkSuccess: ["payload"],
  candidatesCreateBenefitsBulkFail: ["error"],
});

const INITIAL_STATE = {
  formations: [],
  beneficiosList: [],
  isLoading: false,
  isLoadingSelectOptions: false,
  filter: [],
  benefitsBulk: [],
};

const getUserBenefits = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: true,
});

const getUserBenefitsSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});

const getUserBenefitsFail = (state, error) => ({
  ...state,
  error,
  isLoading: false,
});



const getUserBenefitsFiltered = (state, filter) => ({
  ...state,
  filter,
  isLoading: true,
});

const getUserBenefitsFilteredSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});

const getUserBenefitsFilteredFail = (state, error) => ({
  ...state,
  error,
  isLoading: false,
});



const createUserBenefits = (state, payload) => ({
  ...state,
  payload,
  isLoading: true,
});

const createUserBenefitsSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});

const createUserBenefitsFail = (state, error) => ({
  ...state,
  error,
  isLoading: false,
});



const getUserBenefitsById = (state, payload) => ({
  ...state,
  payload,
  isLoading: true,
});

const getUserBenefitsByIdSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});

const getUserBenefitsByIdFail = (state, error) => ({
  ...state,
  error,
  isLoading: false,
});



const removeValeTransporteById = (state, payload) => ({
  ...state,
  payload,
  isLoading: true,
});

const removeValeTransporteByIdSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});

const removeValeTransporteByIdFail = (state, error) => ({
  ...state,
  error,
  isLoading: false,
});



const atualizarValeTransporteById = (state, payload) => ({
  ...state,
  payload,
  isLoading: true,
});

const atualizarValeTransporteByIdSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});

const atualizarValeTransporteByIdFail = (state, error) => ({
  ...state,
  error,
  isLoading: false,
});


const atualizarValeRefeicaoById = (state, payload) => ({
  ...state,
  payload,
  isLoading: true,
});

const atualizarValeRefeicaoByIdSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});

const atualizarValeRefeicaoByIdFail = (state, error) => ({
  ...state,
  error,
  isLoading: false,
});



const removerBeneficioById = (state, payload) => ({
  ...state,
  payload,
  isLoading: true,
});

const removerBeneficioByIdSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});

const removerBeneficioByIdFail = (state, error) => ({
  ...state,
  error,
  isLoading: false,
});



const removerValeRefeicao = (state, payload) => ({
  ...state,
  payload,
  isLoading: true,
});

const removerValeRefeicaoSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});

const removerValeRefeicaoFail = (state, error) => ({
  ...state,
  error,
  isLoading: false,
});

const candidatesExportBenefitsInformationRequest = (state) => ({
  ...state,
  isLoading: true
})

const candidatesExportBenefitsInformationSuccess = (state, payload) => ({
  ...state,
  isLoading: false
})

const candidatesExportBenefitsInformationFail = (state, error) => ({
  ...state,
  ...error,
  isLoading: false
})

const candidatesCreateBenefitsBulkRequest = (state) => ({
  ...state,
  isLoading: true
})

const candidatesCreateBenefitsBulkSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false
})

const candidatesCreateBenefitsBulkFail = (state, error) => ({
  ...state,
  ...error,
  isLoading: false
})



export default createReducer(INITIAL_STATE, {
  [Types.GET_USER_BENEFITS]: getUserBenefits,
  [Types.GET_USER_BENEFITS_SUCCESS]: getUserBenefitsSuccess,
  [Types.GET_USER_BENEFITS_FAIL]: getUserBenefitsFail,

  [Types.GET_USER_BENEFITS_FILTERED]: getUserBenefitsFiltered,
  [Types.GET_USER_BENEFITS_FILTERED_SUCCESS]: getUserBenefitsFilteredSuccess,
  [Types.GET_USER_BENEFITS_FILTERED_FAIL]: getUserBenefitsFilteredFail,

  [Types.CREATE_USER_BENEFITS]: createUserBenefits,
  [Types.CREATE_USER_BENEFITS_SUCCESS]: createUserBenefitsSuccess,
  [Types.CREATE_USER_BENEFITS_FAIL]: createUserBenefitsFail,

  [Types.GET_USER_BENEFITS_BY_ID]: getUserBenefitsById,
  [Types.GET_USER_BENEFITS_BY_ID_SUCCESS]: getUserBenefitsByIdSuccess,
  [Types.GET_USER_BENEFITS_BY_ID_FAIL]: getUserBenefitsByIdFail,

  [Types.REMOVE_VALE_TRANSPORTE_BY_ID]: removeValeTransporteById,
  [Types.REMOVE_VALE_TRANSPORTE_BY_ID_SUCCESS]: removeValeTransporteByIdSuccess,
  [Types.REMOVE_VALE_TRANSPORTE_BY_ID_FAIL]: removeValeTransporteByIdFail,

  [Types.ATUALIZAR_VALE_TRANSPORTE_BY_ID]: atualizarValeTransporteById,
  [Types.ATUALIZAR_VALE_TRANSPORTE_BY_ID_SUCCESS]:
    atualizarValeTransporteByIdSuccess,
  [Types.ATUALIZAR_VALE_TRANSPORTE_BY_ID_FAIL]: atualizarValeTransporteByIdFail,

  [Types.ATUALIZAR_VALE_REFEICAO_BY_ID]: atualizarValeRefeicaoById,
  [Types.ATUALIZAR_VALE_REFEICAO_BY_ID_SUCCESS]:
    atualizarValeRefeicaoByIdSuccess,
  [Types.ATUALIZAR_VALE_REFEICAO_BY_ID_FAIL]: atualizarValeRefeicaoByIdFail,

  [Types.REMOVER_BENEFICIO_BY_ID]: removerBeneficioById,
  [Types.REMOVER_BENEFICIO_BY_ID_SUCCESS]: removerBeneficioByIdSuccess,
  [Types.REMOVER_BENEFICIO_BY_ID_FAIL]: removerBeneficioByIdFail,

  [Types.REMOVER_VALE_REFEICAO]: removerValeRefeicao,
  [Types.REMOVER_VALE_REFEICAO_SUCCESS]: removerValeRefeicaoSuccess,
  [Types.REMOVER_VALE_REFEICAO_FAIL]: removerValeRefeicaoFail,

  [Types.CANDIDATES_EXPORT_BENEFITS_INFORMATION_REQUEST]: candidatesExportBenefitsInformationRequest,
  [Types.CANDIDATES_EXPORT_BENEFITS_INFORMATION_SUCCESS]: candidatesExportBenefitsInformationSuccess,
  [Types.CANDIDATES_EXPORT_BENEFITS_INFORMATION_FAIL]: candidatesExportBenefitsInformationFail,

  [Types.CANDIDATES_CREATE_BENEFITS_BULK_REQUEST]: candidatesCreateBenefitsBulkRequest,
  [Types.CANDIDATES_CREATE_BENEFITS_BULK_SUCCESS]: candidatesCreateBenefitsBulkSuccess,
  [Types.CANDIDATES_CREATE_BENEFITS_BULK_FAIL]: candidatesCreateBenefitsBulkFail,
});
