import * as urls from "../../utils/contants/urls";

import { Body, FlexAction, Span, SubmitRow } from "./VacancyFormStyle";
import {
  ButtonDefault,
  ButtonSecondary,
  SelectFieldInput,
  TextField
} from "components/shared/CustomInputs";
import { FormattedMessage, injectIntl } from "react-intl";
import React, { useEffect, useState } from "react";
import {
  formatErrorsMessages,
  validateSchedules
} from "../../helpers/sharedHelpers";

import BreadCrumb from "components/Layout/breadcrumb";
import { Col } from "react-bootstrap";
import DialogBankOfTalents from "./DialogBankOfTalents";
import DialogBanner from "./DialogBanner";
import DialogShared from "./DialogShared";
import ExternalBankOfTalents from "components/Forms/ExternalBankOfTalents";
import ExternalExistingVacancy from "components/Forms/external/ExternalExistingVacancy";
import FormGroup from "../Forms/group/FormGroup";
import { HASH_KEY } from "../../utils/contants/tokens";
import InternalBankOfTalents from "components/Forms/internal";
import InternalExistingVacancy from "components/Forms/InternalExistingVacancy";
import InternalPromotionVacancy from "components/Forms/InternalPromotionVacancy";
import { Link } from "react-router-dom";
import Load from "../Layout/Load";
import { PREVIEW_PAGE } from "../../utils/contants/urls";
import PropTypes from "prop-types";
import { YesOrNoDialog } from "components/shared/CustomComponents";
import { connect } from "react-redux";
import { Creators as formInputsAction } from "../../store/ducks/formInputs";
import { Creators as messageAction } from "store/ducks/messages";
import { required } from "../../helpers/validations";
import { Creators as vacancyAction } from "store/ducks/vacancy";

const jwt = require("jsonwebtoken");

const VacancyForm = ({
  vaga,
  location,
  inputs,
  saveVacancy,
  inputActions,
  getManagerByName,
  requestPreview,
  intl,
  preview,
  publishVacancy,
  editing,
  updateVacancy,
  messagePublication,
  resetPreview,
  requestResultCenterByAffiliate,
  requestJobsFromArea,
  getIputValues,
  managerName,
}) => {
  const [showInternalBd, setInternalJob] = useState();
  const [showTalents, setTalents] = useState();
  const [formValues, setFormValues] = useState({
    aboutWork: "",
    area: "",
    areaFormation: [],
    competence: [],
    candidatos: [],
    benefits: [],
    city: [],
    code: 0,
    eligibleJobs: [],
    fluxStatus: "",
    id: undefined,
    showSalary: false,
    informeSalary: false,
    informeSchedules: false,
    deficiency: false,
    job: "",
    jobName: "",
    jornada: "",
    jorney: "",
    placeOfWork: "",
    responsibility: "",
    salary: "",
    schooling: "",
    skills: "",
    status: "enabled",
    timeOfWork: "",
    vacancyReq: "",
    vacancyTitle: "",
    lastPromotion: "",
    vacancys: 0,
    startWork: "",
    affiliate: 0,
    resultCenter: 0,
    section: 0,
    taker: 0,
    managerName: 0,
    managerCode: "",
    published: false,
    indicators: [],
    vacancyGroup: ""
  });
  const [formErros, setFormErros] = useState([]);
  const [formValidators, setFormValidators] = useState();
  const [showDialogBankOfTalents, setShowDialogBankOfTalents] = useState(false);
  const [showDialogBanner, setShowDiagloBanner] = useState(false);
  const [submited, setSubmited] = useState(false);
  const [showDialogPublication, setShowDialogPublication] = useState(false);
  const [showDialogShared, setShowDialogShare] = useState(false);
  const [schedules, setSchedulesItems] = useState([]);
  const [managerList, setManagerList] = useState([])

  useEffect(() => {
    setInternalJob(vaga.kindSelecao);
    setTalents(vaga.kindVaga);
    setFormValues({...vaga, area: vaga.area?.id ? vaga.area.id : vaga.area, job: vaga.job?.id ? vaga.job.id : vaga.job });
    setSchedulesItems(vaga.schedules);
    getInputsEditing();
    if (vaga.managerName instanceof Object)
      setManagerList([vaga.managerName]);
  }, [vaga]);

  useEffect(() => {
    if (managerName.length > 0)
      setManagerList(managerName)
  }, [managerName]);

  useEffect(() => {
    if (showInternalBd === "internal") {
      setFormValues({
        ...formValues,
        vacancyReq:  `
        <p>Desejável no mínimo 03 meses de empresa;&nbsp;</p>\n<p>Desejável no mínimo de 03 meses da última promoção;&nbsp;</p>\n<p>Não ter sido reprovado em processo seletivo interno para a mesma vaga há menos de dois meses;&nbsp;</p>\n<p>Não possuir absenteísmo injustificado nos últimos 90 dias fechados à divulgação da vaga e durante todo o processo seletivo;&nbsp;</p>\n<p>Não ter histórico de advertências e/ou suspensão (medida disciplinar) nos últimos 90 dias anterior à data limite para inscrição, e também, durante o processo seletivo;&nbsp;</p>\n<p>Para todos os cargos que possuem a ferramenta Robbyson, necessário estar nos níveis G1 ou G2 (Estrela ou Nuvem) no penúltimo mês fechado;</p>\n<p>Estar de acordo com as normas de comportamento da empresa, conforme especificado no REG013 – Gestão de Pessoas, disponível na intranet do Gestão Empresarial;</p>\n<p>Caso o colaborador desista, não será possível reverter sua inscrição;</p>\n<p>Eventual transferência de local de trabalho devido aprovação no processo seletivo, gerará renúncia ao cargo da CIPA e sua estabilidade;</p>\n<p>O colaborador não pode concorrer a duas vagas simultâneas;</p>\n<p>Ao se inscrever em uma vaga, desiste de sua participação em outros processos seletivos que esteja participando.</p>
        `
      });
    } else {
      setFormValues({
        ...formValues,
        vacancyReq: ""
      });
    }
  }, [showInternalBd]);

  const renderComponent = () => {
    if (showTalents === "talents" && showInternalBd === "internal") {
      return (
        <InternalBankOfTalents
          formValues={formValues}
          setFormValues={setFormValues}
          inputActions={inputActions}
          inputsItems={{ ...inputs }}
          setFormErros={setFormErros}
          formValidators={setFormValidators}
          submited={submited}
          formErros={formErros}
          setSchedulesItems={setSchedulesItems}
          schedules={schedules}
          onSelectArea={onSelectArea}
          editing={editing}
          getIputValues={getIputValues}
        />
      );
    }
    if (showTalents === "talents" && showInternalBd === "external") {
      return (
        <ExternalBankOfTalents
          formValues={formValues}
          setFormValues={setFormValues}
          inputActions={inputActions}
          inputsItems={{ ...inputs }}
          setFormErros={setFormErros}
          formValidators={setFormValidators}
          submited={submited}
          formErros={formErros}
          setSchedulesItems={setSchedulesItems}
          schedules={schedules}
          onSelectArea={onSelectArea}
        />
      );
    }
    if (showTalents === "existJob" && showInternalBd === "external") {
      return (
        <ExternalExistingVacancy
          formValues={formValues}
          setFormValues={setFormValues}
          inputActions={inputActions}
          inputsItems={{ ...inputs }}
          setFormErros={setFormErros}
          formValidators={setFormValidators}
          getManagerByName={getManagerByName}
          submited={submited}
          formErros={formErros}
          setSchedulesItems={setSchedulesItems}
          schedules={schedules}
          requestResultCenterByAffiliate={requestResultCenterByAffiliate}
          onSelectArea={onSelectArea}
        />
      );
    }
    if (showTalents === "existJob" && showInternalBd === "internal") {
      return (
        <InternalExistingVacancy
          formValues={formValues}
          setFormValues={setFormValues}
          inputActions={inputActions}
          inputsItems={{ ...inputs }}
          setFormErros={setFormErros}
          formValidators={setFormValidators}
          submited={submited}
          formErros={formErros}
          getManagerByName={getManagerByName}
          setSchedulesItems={setSchedulesItems}
          schedules={schedules}
          requestResultCenterByAffiliate={requestResultCenterByAffiliate}
          onSelectArea={onSelectArea}
          editing={editing}
          getIputValues={getIputValues}
          managerList={managerList}
        />
      );
    }
    if (showTalents === "promotion" && showInternalBd === "internal") {
      return (
        <InternalPromotionVacancy
          formValues={formValues}
          setFormValues={setFormValues}
          inputActions={inputActions}
          inputsItems={{ ...inputs }}
          setFormErros={setFormErros}
          formValidators={setFormValidators}
          submited={submited}
          formErros={formErros}
          getManagerByName={getManagerByName}
          setSchedulesItems={setSchedulesItems}
          schedules={schedules}
          requestResultCenterByAffiliate={requestResultCenterByAffiliate}
          onSelectArea={onSelectArea}
          editing={editing}
          managerList={managerList}
        />
      );
    }
  };

  const getInputsEditing = () => {
    if (editing && vaga.area && vaga.area.id) {
      vaga.area.id && requestJobsFromArea(vaga.area.id);
    }
  };

  const openPreview = banner => {
    requestPreview(vaga.id, banner);
  };

  const saveSessionRail= () => {

    sessionStorage.setItem("dataFormVacancy", JSON.stringify(formValues));
  };


  useEffect(() => {
    if (preview.id && !vaga.published) {
      const hashIdVacancy = jwt.sign(preview.id, HASH_KEY);
      window.open(`${PREVIEW_PAGE}${preview.title}?preview=${hashIdVacancy}`);

      setFormValues({ ...formValues, idBanner: preview.idBanner });
      setShowDiagloBanner(!showDialogBanner);
      setShowDialogPublication(!showDialogPublication);
    }
  }, [preview]);

  useEffect(() => {
    setSubmited(false);
  }, [showInternalBd, showTalents]);

  const saveVacancyForm = () => {
    setSubmited(true);
    const schedulesErros = validateSchedules(schedules);
    if (!Object.keys(formErros).length && !schedulesErros.length) {
      saveValidVacancy();
    } else {
      const formErrosMessages = formatErrorsMessages(formErros);
      const messages = formErrosMessages.concat(schedulesErros);
      messagePublication(messages);
    }
  };

  const saveValidVacancy = infoAboutBank => {
    if (vaga.id) {
      updateVacancy(
        Object.assign(formValues, {
          kindSelecao: showInternalBd,
          kindVaga: showTalents,
          title: formValues.title,
          schedules: schedules
        })
      );
    } else {
      saveVacancy(
        Object.assign(formValues, {
          kindSelecao: showInternalBd,
          kindVaga: showTalents,
          title: formValues.title,
          schedules: schedules
        })
      );
    }
  };

  const openDialogToSelectImage = () => {
    setShowDiagloBanner(!showDialogBanner);
  };

  const submitPublication = () => {
    setShowDialogPublication(!showDialogPublication);
    publishVacancy(formValues.id, formValues.idBanner);
    setShowDialogShare(!showDialogShared);
  };

  const beforePublishDialog = () => {
    if (vaga.published) setShowDialogShare(!showDialogShared);
    else openDialogToSelectImage();
  };

  const onSelectArea = area => {
    requestJobsFromArea(area);
    setFormValues({ ...formValues, area: area });
  };

  const renderTitle = () => {
    if (!editing) return <FormattedMessage id="vacancyList.newVacancy" />;
    else return <FormattedMessage id="vacancyList.editVacancy" />;
  };

  const renderBodyTitle = () => {
    if (!editing) return <FormattedMessage id="vacancyCreate.createVacancy" />;
    else
      return (
        <Span>
          <FormattedMessage id="vacancyCreate.editVacancy" />
          <Span propStyle={{ marginLeft: "5px" }}>{vaga.id}</Span>
        </Span>
      );
  };

  const renderPublishButtonTitle = () => {
    if (!vaga.published)
      return <FormattedMessage id="sharedItems.publishVacancy" />;
    else return <FormattedMessage id="sharedItems.shareVacancy" />;
  };

  const renderSaveButtonTitle = () => {
    if (!editing) return <FormattedMessage id="sharedItems.save" />;
    else return <FormattedMessage id="sharedItems.edit" />;
  };

  return (
    <React.Fragment>
      {vaga.isLoading || inputs.isLoading ? <Load isLoading /> : null}
      <React.Fragment>
        <SubmitRow className="row">
          <Col lg="5">
            <BreadCrumb location={location} title={renderTitle()} />
          </Col>
          <Col lg={3} style={{ padding: "5px" }}>
            <FlexAction>
              <Link
                style={{
                  width: "100%",
                  pointerEvents: vaga.id === undefined ? "none" : "auto"
                }}
                to={`${urls.RAILS_DETAILS_ASSOCIATION}/${vaga.id}`}
              >
                <ButtonSecondary
                  onClick={() =>{
                    saveSessionRail();
                  }}
                  disabled={vaga.id == undefined}
                  name={
                    vaga.rails === undefined ? (
                      <FormattedMessage id="sharedItems.affiliateRails" />
                    ) : (
                      <FormattedMessage id="sharedItems.viewRails" />
                    )
                  }
                />
              </Link>
            </FlexAction>
          </Col>
          <div style={{ padding: "5px" }} className="col-lg-2">
            <FlexAction>
              <ButtonDefault
                onClick={() => saveVacancyForm()}
                name={renderSaveButtonTitle()}
              />
            </FlexAction>
          </div>
          <div style={{ padding: "5px" }} className=" col-lg-2">
            <FlexAction>
              <ButtonDefault
                onClick={() => beforePublishDialog()}
                disabled={vaga.id === undefined}
                name={renderPublishButtonTitle()}
              />
            </FlexAction>
          </div>
        </SubmitRow>
      </React.Fragment>
      <Body>
        <FormGroup formName={renderBodyTitle()}>
          <div className="row">
            <div className="col-md-4">
              <TextField
                onChange={event =>
                  setFormValues({
                    ...formValues,
                    vacancyTitle: event.target.value
                  })
                }
                validations={[required]}
                setFormErros={setFormErros}
                formValidators={setFormValidators}
                touched={submited}
                formErr={formErros}
                label={<FormattedMessage id="inputs.titleVaga" />}
                value={formValues.vacancyTitle}
                name={intl.formatMessage({ id: "inputs.titleVaga" })}
              />
            </div>
            <div className="col-md-4">
              <SelectFieldInput
                items={[
                  {
                    name: intl.formatMessage({
                      id: "vacancyCreate.fields.typeForm.internal"
                    }),
                    id: "internal"
                  },
                  {
                    name: intl.formatMessage({
                      id: "vacancyCreate.fields.typeForm.external"
                    }),
                    id: "external"
                  }
                ]}
                onChange={event => {
                  setFormErros([]);
                  setInternalJob(event.target.value);
                }}
                disabled={formValues && formValues.id !== undefined}
                label={<FormattedMessage id="sharedItems.selection" />}
                value={showInternalBd}
              />
            </div>
            <div className="col-md-4">
              <SelectFieldInput
                items={[
                  {
                    name: intl.formatMessage({
                      id: "vacancyCreate.fields.kindVaga.bdTalents"
                    }),
                    id: "talents"
                  },
                  {
                    name: intl.formatMessage({
                      id: "vacancyCreate.fields.kindVaga.existentVacancy"
                    }),
                    id: "existJob"
                  },
                  {
                    name: intl.formatMessage({
                      id: "vacancyCreate.fields.kindVaga.promotion"
                    }),
                    id: "promotion",
                    disabled: showInternalBd !== "internal"
                  }
                ]}
                onChange={event => {
                  setFormErros([]);
                  setTalents(event.target.value);
                }}
                value={showTalents}
                disabled={formValues && formValues.id !== undefined}
                label={<FormattedMessage id="sharedItems.vacancy" />}
              />
            </div>
          </div>
          {showInternalBd && renderComponent()}
        </FormGroup>
      </Body>
      <DialogBankOfTalents
        action={infoAboutBank => saveValidVacancy(infoAboutBank)}
        closeDialog={() => setShowDialogBankOfTalents(false)}
        show={showDialogBankOfTalents}
      />
      <DialogBanner
        closeDialog={() => setShowDiagloBanner(!showDialogBanner)}
        show={showDialogBanner}
        openPreview={banner => openPreview(banner)}
        banners={inputs.banners}
      />
      <DialogShared
        closeDialog={() => setShowDiagloBanner(!showDialogBanner)}
        show={showDialogShared && vaga.published}
        openPreview={() => setShowDialogShare(!showDialogShared)}
        slug={vaga.slug}
      />
      <YesOrNoDialog
        confirmation={<FormattedMessage id="sharedItems.confirmPublication" />}
        question={<FormattedMessage id="sharedItems.publishVacancyBanner" />}
        show={showDialogPublication}
        confirmAction={() => submitPublication()}
        cancelAction={() => {
          resetPreview();
          setShowDialogPublication(!showDialogPublication);
        }}
      />
    </React.Fragment>
  );
};

const mapDispatchToProps = dispatch => ({
  requestPreview: (id, banner) =>
    dispatch(vacancyAction.jobCreatePreviewRequest(id, banner)),
  resetPreview: () => dispatch(vacancyAction.jobResetPreview()),
  publishVacancy: (id, idBanner) =>
    dispatch(vacancyAction.jobPublishRequest(id, idBanner)),
  messagePublication: messages =>
    dispatch(messageAction.messagePublication(messages)),
  requestResultCenterByAffiliate: id =>
    dispatch(formInputsAction.formInputsAffiliateRequest(id)),
  requestJobsFromArea: idArea =>
    dispatch(formInputsAction.formInputsRequestJobFromArea(idArea)),
  getIputValues: kind => dispatch(formInputsAction.formInputsRequest(kind)),
  getManagerByName: name => dispatch(formInputsAction.formInputsSearchByNameRequest(name))
});


function mapStateToProps(state) {
  const  todos = state;
  return {
    vacancy: state.vaga,
    preview: state.vaga.preview,
    managerName: state.formInputs.managerName,
  }
}



VacancyForm.propTypes = {
  forgotAction: PropTypes.func,
  submit: PropTypes.func
};

VacancyForm.defaultProps = {
  submit: () => {},
  forgotAction: () => {}
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(VacancyForm));
