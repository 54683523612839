import "moment/locale/pt-br";

import * as routes from "../../utils/contants/urls";
import * as urlsApi from "../../utils/contants/urlsApi";
import {
  buildErroHandleItems,
  buildPagination,
} from "../../helpers/sharedHelpers";
import { put, select, takeLatest } from "redux-saga/effects";
import { Types as AlertTypes } from "../ducks/alert";
import requestAPI from "../../helpers/requestHelpers";
import API from "../../utils/API";
import { Types as MessageTypes } from "../ducks/messages";
import { Types } from "../ducks/assinaturaAdministrativo";
import moment from "moment";
import { DELETE, GET, POST, PUT } from "../../utils/contants/verbs";
moment.locale("pt-BR");


export const calcAge = birth => {
  if (birth) {
    const date = moment().diff(birth, "years");
    return `${date} anos`;
  }
  return null;
};

export const formatterDateBrazilian = value => {
  if (value) return moment(value).format("L");
  return " - ";
};

export const formatterDateJustDate = date => {
  if (date.length == 19) {
    const onlyDate = date.split("T");
    const dateItems = onlyDate.length
      ? onlyDate[0].split("-")
      : date.split("-");
    const day = dateItems[2];
    const month = dateItems[1];
    const year = dateItems[0];
    return `${year}-${month}-${day}`;
  }
  return null
};

function requestGetCandidatesListAssinature(body) {
  return API.put(`${urlsApi.PLUG_ASSINATURA_PENDENTE_LIST}`, body)
    .then((res) => res.data)
    .catch((error) => {
      throw error;
    });
}

function* getCandidatesList({ payload }) {
  try {
   /*  const actualPage = payload && payload.firstPage ? 1 : yield select(getActualPage);
    const actualItens =
      payload && payload.recoveredFilter && payload.quantidadeItens
        ? payload.quantidadeItens
        : 20;

    payload.cpfList = payload.cpfs;
    payload.quantidadeItens = actualItens;
    payload.numeroPagina = actualPage;
    payload.idStatusList = payload.idStatus; */


    const body = { ...payload }
    const {lista, paginacao} = yield requestGetCandidatesListAssinature(body);
    console.log("requestGetCandidatesListAssinature", lista)
    //const nextPage = actualPage + 1;

    let allCandidates;
    const stateCandidateList = yield select(getStateCandidatesList);
    if (!payload.firstPage){
      allCandidates = stateCandidateList.concat(lista);
    }
    else{
      allCandidates = lista;
    }

    const paginacaoNumber = {...paginacao, page: payload.numeroPagina}
    console.log("paginacaoNumber", paginacaoNumber)
    const pagination = buildPagination(
    paginacaoNumber,
    payload,
    allCandidates,
    stateCandidateList,
    50,
  );

    yield put({
      type: Types.CANDIDATES_GET_CANDIDATES_LIST_ASSINATURE_SUCCESS,
      candidates: allCandidates,
     // page: nextPage,
      pagination
    });
  } catch (error) {
    yield put({ type: Types.CANDIDATES_GET_CANDIDATES_LIST_ASSINATURE_FAIL, error });
  }
}





function requestPasswordReset(body) {
  return API.post(`${urlsApi.RELEASE_PASSWORD_RESET}`, body.candidates, {
    responseType: "application/json"
  })
    .catch(error => {
      throw error;
    });
}





function* passwordReset( payload ) {
  try {
    yield requestPasswordReset(payload);
    yield put({ type: Types.CANDIDATES_PASSWORD_RESET_SUCCESS });
    const messages = [{ type: "info", message: "Sucesso ao resetar as senhas dos colaboradores." }];
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION_SUCCESS, messages });
  } catch (error) {
    const errors = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages: errors });
    yield put({ type: Types.CANDIDATES_PASSWORD_RESET_FAIL, error });
  }
}

const getStateCandidatesList = state => state.candidates.candidates;
const getActualPage = state => state.candidates?.page;


export function* watcherSaga() {
  yield takeLatest(Types.CANDIDATES_PASSWORD_RESET_REQUEST, passwordReset);
  
  yield takeLatest(Types.CANDIDATES_GET_CANDIDATES_LIST_ASSINATURE_REQUEST, getCandidatesList);
}
