import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  getUserPayments: [""],
  getUserPaymentsSuccess: ["payload", "isLoading"],
  getUserPaymentsFail: ["error", "isLoading"],

  getUserPaymentsFiltered: ["filter"],
  getUserPaymentsFilteredSuccess: ["payload", "isLoading"],
  getUserPaymentsFilteredFail: ["error", "isLoading"],

  atualizarUserPaymentsById: ["benefit"],
  atualizarUserPaymentsByIdSuccess: ["payload", "isLoading"],
  atualizarUserPaymentsByIdFail: ["error", "isLoading"],

  inativarUserBenefitsById: ["benefit"],
  inativarUserBenefitsByIdSuccess: ["payload", "isLoading"],
  inativarUserBenefitsByIdFail: ["error", "isLoading"],

  createUserPayments: ["payload"],
  createUserPaymentsSuccess: ["payload", "isLoading"],
  createUserPaymentsFail: ["error", "isLoading"],

  filtrarPagamento: ["payload"],
  filtrarPagamentoSuccess: ["payload", "isLoading"],
  filtrarPagamentoFail: ["error", "isLoading"],

  candidatesExportPaymentsInformationRequest: ["payload", "vacancyId"],
  candidatesExportPaymentsInformationSuccess: ["payload"],
  candidatesExportPaymentsInformationFail: ["error"],
});

const INITIAL_STATE = {
  formations: [],
  beneficiosList: [],
  isLoading: false,
  isLoadingSelectOptions: false,
  filter: [],
  benefit: {},
  PagamentosList: []
};

const getUserPayments = (state) => ({
  ...state,
  isLoading: true,
});

const getUserPaymentsSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});

const getUserPaymentsFail = (state, error) => ({
  ...state,
  error,
  isLoading: false,
});



const getUserPaymentsFiltered = (state, filter) => ({
  ...state,
  filter,
  isLoading: true,
});

const getUserPaymentsFilteredSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});

const getUserPaymentsFilteredFail = (state, error) => ({
  ...state,
  error,
  isLoading: false,
});



const atualizarUserPaymentsById = (state, payload) => ({
  ...state,
  payload,
  isLoading: true,
});

const atualizarUserPaymentsByIdSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});

const atualizarUserPaymentsByIdFail = (state, error) => ({
  ...state,
  error,
  isLoading: false,
});


const inativarUserBenefitsById = (state, payload) => ({
  ...state,
  payload,
  isLoading: true,
});

const inativarUserBenefitsByIdSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});

const inativarUserBenefitsByIdFail = (state, error) => ({
  ...state,
  error,
  isLoading: false,
});


const createUserPayments = (state, payload) => ({
  ...state,
  payload,
  isLoading: true,
});

const createUserPaymentsSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});

const createUserPaymentsFail = (state, error) => ({
  ...state,
  error,
  isLoading: false,
});


const filtrarPagamento = (state, payload) => ({
  ...state,
  payload,
  isLoading: true,
});

const filtrarPagamentoSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});

const filtrarPagamentoFail = (state, error) => ({
  ...state,
  error,
  isLoading: false,
});

const candidatesExportPaymentsInformationRequest = (state) => ({
  ...state,
  isLoading: true
})

const candidatesExportPaymentsInformationSuccess = (state, payload) => ({
  ...state,
  isLoading: false
})

const candidatesExportPaymentsInformationFail = (state, error) => ({
  ...state,
  ...error,
  isLoading: false
})



export default createReducer(INITIAL_STATE, {
  [Types.GET_USER_PAYMENTS]: getUserPayments,
  [Types.GET_USER_PAYMENTS_SUCCESS]: getUserPaymentsSuccess,
  [Types.GET_USER_PAYMENTS_FAIL]: getUserPaymentsFail,

  [Types.GET_USER_PAYMENTS_FILTERED]: getUserPaymentsFiltered,
  [Types.GET_USER_PAYMENTS_SUCCESS]: getUserPaymentsFilteredSuccess,
  [Types.GET_USER_PAYMENTS_FILTERED_FAIL]: getUserPaymentsFilteredFail,

  [Types.ATUALIZAR_USER_PAYMENTS_BY_ID]: atualizarUserPaymentsById,
  [Types.ATUALIZAR_USER_PAYMENTS_BY_ID_SUCCESS]:
    atualizarUserPaymentsByIdSuccess,
  [Types.ATUALIZAR_USER_PAYMENTS_BY_ID_FAIL]: atualizarUserPaymentsByIdFail,

  [Types.INATIVAR_USER_BENEFITS_BY_ID]: inativarUserBenefitsById,
  [Types.INATIVAR_USER_BENEFITS_BY_ID_SUCCESS]: inativarUserBenefitsByIdSuccess,
  [Types.INATIVAR_USER_BENEFITS_BY_ID_FAIL]: inativarUserBenefitsByIdFail,

  [Types.CREATE_USER_PAYMENTS]: createUserPayments,
  [Types.CREATE_USER_PAYMENTS_SUCCESS]: createUserPaymentsSuccess,
  [Types.CREATE_USER_PAYMENTS_FAIL]: createUserPaymentsFail,

  [Types.FILTRAR_PAGAMENTO]: filtrarPagamento,
  [Types.FILTRAR_PAGAMENTO_SUCCESS]: filtrarPagamentoSuccess,
  [Types.FILTRAR_PAGAMENTO_FAIL]: filtrarPagamentoFail,

  [Types.CANDIDATES_EXPORT_PAYMENTS_INFORMATION_REQUEST]: candidatesExportPaymentsInformationRequest,
  [Types.CANDIDATES_EXPORT_PAYMENTS_INFORMATION_SUCCESS]: candidatesExportPaymentsInformationSuccess,
  [Types.CANDIDATES_EXPORT_PAYMENTS_INFORMATION_FAIL]: candidatesExportPaymentsInformationFail,
});


