import { put, takeLatest, select, call } from "redux-saga/effects";
import * as routes from "../../utils/contants/urls";
import * as urlsApi from "../../utils/contants/urlsApi";
import API from "../../utils/API";
import { Types } from "../ducks/userBenefits";

import { Types as MessageTypes } from "../ducks/messages";
import { buildErroHandleItems } from "../../helpers/sharedHelpers";

import { buildPagination } from "../../helpers/sharedHelpers";
const getStateCandidatesList = state => state.userBenefits?.beneficiosList;

function* getUserBenefits() {

  try {
    const response = yield API.get(`${urlsApi.GET_USER_BENEFITS}`)
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });

    const beneficiosList = response.lista.map((beneficios, index) => {
      return {
        id: beneficios.id,
        cpf: beneficios.cpf,
        diasUteis: beneficios.diasUteis,
        ausencias: beneficios.ausencias,
        candidatoNome: beneficios.candidatoNome,
        candidato_Id: beneficios.candidato_Id,
        mesCompetencia: converterDataParaBrasileiro(beneficios.mesCompetencia),
        valeRefeicao: beneficios.valeRefeicao,
        valeTransporte: beneficios.valeTransporte,
      };
    });


    yield put({
      type: Types.GET_USER_BENEFITS_FILTERED,
      beneficiosList,
    });

  } catch (error) {
    const messages = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });

    yield put({ type: Types.GET_USER_BENEFITS_FAIL, error });
  }
}

function* getUserBenefitsFiltered(filters) {
  try {

    const filtros = filters.filter;
    const stateBeneficios = yield select(getStateCandidatesList);
    const numeroPagina = filtros.NumeroPagina || 1;

    const payload = {
      nomeFuncionario: filtros.nomeFuncionario ? filtros.nomeFuncionario.toString().trim() : "",
      cpfList: filtros.cpfList || [],
      mesCompetencia: filtros.mesCompetencia || "",
      NumeroPagina: numeroPagina,
    };

    const { lista, paginacao } = yield API.put(`${urlsApi.GET_USER_BENEFITS_FILTERED}`, payload)
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });

    const beneficiosList = lista.map((beneficios, index) => {
      return {
        id: beneficios.id,
        cpf: beneficios.cpf,
        diasUteis: beneficios.diasUteis,
        ausencias: beneficios.ausencias,
        candidatoNome: beneficios.candidatoNome,
        candidato_Id: beneficios.candidato_Id,
        mesCompetencia: converterMesCompetencia(beneficios.mesCompetencia),
        dataCriacao: converterDataParaBrasileiro(beneficios.dataCriacao),
        dataAtualizacao:
          beneficios.dataAtualizacao != null
            ? converterDataParaBrasileiro(beneficios.dataAtualizacao)
            : "",
        valeRefeicao: beneficios.valeRefeicao,
        valeTransporte: beneficios.valeTransporte,
      };
    });

    const paginacaoNumber = { ...paginacao, page: numeroPagina };

    const pagination = buildPagination(
      paginacaoNumber,
      filters.filter,
      beneficiosList,
      stateBeneficios,
      20
    );

    yield put({
      type: Types.GET_USER_BENEFITS_SUCCESS,
      beneficiosList,
      pagination
    });

    const messages = [];
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION_SUCCESS, messages });

  } catch (error) {
    const messages = buildErroHandleItems(error.response)
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({ type: Types.GET_USER_BENEFITS_FILTERED_FAIL, error });
  }
}

function* createUserBenefits(benefits) {
  try {
    var payload = benefits.payload;

    payload.valeRefeicao =
      typeof payload.valeRefeicao !== "number"
        ? parseFloat(
            payload.valeRefeicao.toString().replace("R$", "").replace(",", ".")
          )
        : 0;

    payload.valeTransporte.map((e)=>{
      e.valor = parseFloat(
        e.valor.toString().replace("R$", "").replace(",", ".")
      );
    })
    
    const response = yield API.post(`${urlsApi.CREATE_USER_BENEFITS}`, payload)
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });

    const criouBeneficio = response.ItemCriado;

    yield put({
      type: Types.CREATE_USER_BENEFITS_SUCCESS,
      criouBeneficio,
    });

    const messages = [{ type: "info", message: "Sucesso ao criar beneficios." }];
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION_SUCCESS, messages });
  } catch (error) {

    const messages = buildErroHandleItems(error.response)
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({ type: Types.CREATE_USER_BENEFITS_FAIL, error });
  }
}

function* getUserBenefitsById(filters) {
  try {
    const vetor = filters.payload.id.id.toString().split("_");

    var payload = {
      id: vetor[0],
      benefitsId: vetor[1]
    };

    const response = yield API.put(`${urlsApi.GET_USER_BENEFITS_BY_ID}`, payload)
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });

    const beneficiosList = {
      id: response.id,
      cpf: response.cpf,
      diasUteis: response.diasUteis,
      ausencias: response.ausencias,
      candidatoNome: response.candidatoNome,
      candidato_Id: response.candidato_Id,
      mesCompetencia: converterDataParaBrasileiro(response.mesCompetencia),
      valeRefeicao: response.valeRefeicao,
      valeTransporte: response.valeTransporte,
    };

    yield put({
      type: Types.GET_USER_BENEFITS_BY_ID_SUCCESS,
      beneficiosList,
    });
    const messages = [];
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION_SUCCESS, messages });
  } catch (error) {
    yield put({ type: Types.GET_USER_BENEFITS_BY_ID_FAIL, error });

    const messages = buildErroHandleItems(error.response)
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
  }
}

function* removeValeTransporteById(benefits) {

  var payload = {
    id: benefits.benefit.id,
  };

  try {
    const response = yield API.put(
      `${urlsApi.REMOVE_VALE_TRANSPORTE_BY_ID}`,
      payload
    )
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });

    var ItemRemovido = response.ItemRemovido;

    yield put({
      type: Types.REMOVE_VALE_TRANSPORTE_BY_ID_SUCCESS,
      ItemRemovido,
    });
    const messages = [{ type: "info", message: "Sucesso ao remover beneficio." }];
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION_SUCCESS, messages });
  } catch (error) {
    yield put({ type: Types.REMOVE_VALE_TRANSPORTE_BY_ID_FAIL, error });
    const messages = buildErroHandleItems(error.response)
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
  }
}

function* atualizarValeTransporteById(benefits) {

  var Benefits = benefits.benefit.Beneficio;
     
  var valeTransporte = Benefits.valeTransporte[benefits.benefit.indiceBeneficio];
  const vetor = benefits.benefit.userId.toString().split("_");

  var payload = {
    Id: Benefits.id,
    UserId: vetor[0],
    ValeTransporte: {
      idx: valeTransporte.id,
      Passagens: valeTransporte.quantidade,
      Valor: typeof valeTransporte.valor !== "number" ? parseFloat(
        valeTransporte.valor.replace("R$", "").replace(",", "."), 0
      ) : 0,
      Nome: valeTransporte.nome,
    },
  };

  try {
    const response = yield API.put(
      `${urlsApi.ATUALIZA_VALE_TRANSPORTE}`,
      payload
    )
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });

    var ItemAtualizado = response.ItemAtualizado;

    yield put({
      type: Types.ATUALIZAR_VALE_TRANSPORTE_BY_ID_SUCCESS,
      ItemAtualizado,
    });

    const filters = {
      payload: {
        id: benefits.benefit.userId,
        benefitsId: benefits.id,
      },
    };

    
    yield call(getUserBenefitsById, filters)
    window.location.reload();

    const messages = [{ type: "info", message: "Vale transporte atualizado com sucesso" }];
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION_SUCCESS, messages });

  } catch (error) {
    yield put({ type: Types.ATUALIZAR_VALE_TRANSPORTE_BY_ID_FAIL, error });
    const messages = buildErroHandleItems(error.response)
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
  }
}



function* atualizarValeRefeicaoById(benefits) {
  var Benefits = benefits.benefit.Beneficio;

  const vetor = benefits.benefit.userId.toString().split("_");

  var payload = {
    id: Benefits.id,
    UserId: vetor[0],
    ValeRefeicao: typeof benefits.benefit.Beneficio.valeRefeicao !== "number" ? parseFloat(
      benefits.benefit.Beneficio.valeRefeicao.replace("R$", "").replace(",", "."), 0
      ) : 0,
    };

  try {
    const response = yield API.put(`${urlsApi.ATUALIZA_VALE_REFEICAO}`, payload)
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });

    var ItemAtualizado = response.ItemAtualizado;
    var ItemAtualizado = 0;

    yield put({
      type: Types.ATUALIZAR_VALE_REFEICAO_BY_ID_SUCCESS,
      ItemAtualizado,
    });

    const filters = {
      payload: {
        id: benefits.benefit.userId,
        benefitsId: Benefits.id
      }
    }

    yield call(getUserBenefitsById, filters)
    window.location.reload();

    const messages = [
      { type: "info", message: "Vale transporte atualizado com sucesso" },
    ];
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION_SUCCESS, messages });
  } catch (error) {
    yield put({ type: Types.ATUALIZAR_VALE_REFEICAO_BY_ID_FAIL, error });
    const messages = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
  }
}



function* removerBeneficioById(benefits) {
  var payload = {
    id: benefits.benefit.id,
    cpf: benefits.benefit.cpf,
    candidatoId: benefits.benefit.candidato_Id,
  };

  try {
    const response = yield API.put(
      `${urlsApi.REMOVE_USER_BENEFITS_BY_ID}`,
      payload
    )
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });

    var ItemInativado = response.ItemInativado;

    yield put({
      type: Types.REMOVER_BENEFICIO_BY_ID_SUCCESS,
      ItemInativado,
    });

    const messages = [{ type: "info", message: "Sucesso ao inativar beneficio." }];
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION_SUCCESS, messages });
  } catch (error) {
    yield put({ type: Types.REMOVER_BENEFICIO_BY_ID_FAIL, error });
    const messages = buildErroHandleItems(error.response)
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
  }
}


function requestCreateBenefitsBulk(body) {
  return API.put(`${urlsApi.CREATE_BENEFITS_BULK}`, body)
    .then(res => res.data)
    .catch(error => {
      throw error;
    });
}

function* createBenefitsBulk({ benefitsBulk }) {
  try {
    const body = benefitsBulk.map(item => {
      return {
        ...item,
        mesCompetencia: converterDataParaDateTime(item.mesCompetencia)
      };
    });


    const listBulk = yield requestCreateBenefitsBulk(body);
    yield put({ type: Types.CANDIDATES_CREATE_BENEFITS_BULK_SUCCESS, benefitsBulk: listBulk });
    yield put({
      type: MessageTypes.MESSAGE_PUBLICATION,
      messages: [{ type: "info", message: "Candidato criado com sucesso." }]
    });
  } catch (error) {
    const errors = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages: errors });
    yield put({ type: Types.CANDIDATES_CREATE_BENEFITS_BULK_FAIL, error });
  }
}


function* removerValeRefeicao(benefits) {
  const beneficio = benefits.payload.BeneficioParaRemocao;

  var payload = {
    id: beneficio.id,
    userId: beneficio.candidato_Id,
    valeRefeicao: 0,
  };

  try {
    const response = yield API.put(`${urlsApi.REMOVER_VALE_REFEICAO}`, payload)
      .then((res) => res.data)
      .catch((error) => {
        throw error;
      });

    var ItemInativado = response.ItemInativado;

    yield put({
      type: Types.REMOVER_VALE_REFEICAO_SUCCESS,
      ItemInativado,
    });

    const messages = [
      { type: "info", message: "Sucesso ao remover vale refeição." },
    ];
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION_SUCCESS, messages });
  } catch (error) {
    yield put({ type: Types.REMOVER_VALE_REFEICAO_FAIL, error });
    const messages = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
  }
}

function requestExportBenefitsInformations(body) {
  return API.put(`${urlsApi.EXPORT_BENEFITS_INFORMATION}`, body, {
    responseType: "blob"
  })
    .then(res => {
      const blob = new Blob([res.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "dados_candidatos.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch(error => {
      throw error;
    });
}

function* exportBenefitsInformations( payload ) {
  try {
    const filtros = payload.candidates;
    const numeroPagina = filtros.NumeroPagina || 1;
    const filter = {
      nomeFuncionario: filtros.nomeFuncionario || "",
      cpfList: filtros.cpfList || [],
      mesCompetencia: filtros.mesCompetencia || "",
      NumeroPagina: numeroPagina,
    };

    yield requestExportBenefitsInformations(filter);
    yield put({ type: Types.CANDIDATES_EXPORT_BENEFITS_INFORMATION_SUCCESS });
  } catch (error) {
    const errors = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages: errors });
    yield put({ type: Types.CANDIDATES_EXPORT_FAIL, error });
  }
}

function converterMesCompetencia(dataISO) {
  const dataInicial = dataISO.toString().split("T")[0];
  const [ano, mes, dia] = dataInicial.toString().split("-");
  return `${mes}/${ano}`;
}

function converterDataParaBrasileiro(dataISO) {
  const dataInicial = dataISO.toString().split("T")[0];
  const [ano, mes, dia] = dataInicial.toString().split("-");
  return `${dia}/${mes}/${ano}`;
}

function converterDataParaDateTime(dataString) {
  const meses = {
    "jan": 1,
    "fev": 2,
    "mar": 3,
    "abr": 4,
    "mai": 5,
    "jun": 6,
    "jul": 7,
    "ago": 8,
    "set": 9,
    "out": 10,
    "nov": 11,
    "dez": 12
  };

  const [mesAbrev, ano] = dataString.toLowerCase().split("/");
  const mesNumero = meses[mesAbrev];

  if (!mesNumero) {
    return "Mês inválido";
  }

  let anoCompleto = ano.length === 2 ? `20${ano}` : ano;
  const dataFormatada = `${anoCompleto}-${String(mesNumero).padStart(2, '0')}-01`;
  const data = new Date(dataFormatada);

  if (isNaN(data)) {
    return "Data inválida"; 
  }

  return data;
}


export function* watcherSaga() {
  yield takeLatest(Types.GET_USER_BENEFITS, getUserBenefits);
  yield takeLatest(Types.GET_USER_BENEFITS_FILTERED, getUserBenefitsFiltered);
  yield takeLatest(Types.CREATE_USER_BENEFITS, createUserBenefits);

  yield takeLatest(Types.GET_USER_BENEFITS_BY_ID, getUserBenefitsById);
  yield takeLatest(Types.REMOVE_VALE_TRANSPORTE_BY_ID, removeValeTransporteById);
  yield takeLatest(Types.ATUALIZAR_VALE_TRANSPORTE_BY_ID, atualizarValeTransporteById);

  yield takeLatest(Types.ATUALIZAR_VALE_REFEICAO_BY_ID, atualizarValeRefeicaoById);
  yield takeLatest(Types.REMOVER_BENEFICIO_BY_ID, removerBeneficioById);
  yield takeLatest(Types.REMOVER_VALE_REFEICAO, removerValeRefeicao);

  yield takeLatest(Types.CANDIDATES_EXPORT_BENEFITS_INFORMATION_REQUEST, exportBenefitsInformations);
  yield takeLatest(Types.CANDIDATES_CREATE_BENEFITS_BULK_REQUEST, createBenefitsBulk);
}
