import * as routes from "utils/contants/urls";

import {
  ActionItem,
  Actions,
  Label,
  LabelAction,
  LabelHeader,
  Table,
  TableBody,
  TableColumn,
  TableHead,
  TableRow

} from "components/shared/CustomComponentsStyle";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  order,
  statusFinishedFill,
  statusOkFill,
  view,
  order as orderIcon,
} from "assets/icons/icons";
import { StatusDocuments } from "utils/contants/enums";
import { Creators as formInputsActions } from "store/ducks/formInputs";
import { Body } from "./ListDocumentsCandidatesStyle";
import BreadCrumb from "components/Layout/breadcrumb";

import { MultSelectCreatable, MultSelect, SelectFieldInput, CheckBox, ButtonSecondary, TextField } from "components/shared/CustomInputs";
import { Filter, Collapse, SelectInput, DataLabel } from "components/shared/CustomComponents";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import Load from "components/Layout/Load";
import Pagination from "components/Pagination";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import InputMask from "react-input-mask";
import { Creators as documentsAction } from "store/ducks/documents";
import { StatusEtapaDocumentos } from "utils/contants/enums";
import {orderList,
  orderListDescending} from "helpers/sharedHelpers";
import { Creators as messageAction } from "store/ducks/messages";



const ListDocumentsCandidates = ({
  candidateVacancysDocs,
  candidates,
  isLoading,
  pagination,
  location,
  requestInputValues,
  placesOfWork,
  messagePublication,
  hasMore

}) => {

  const [agruparPorVaga, setAgruparPorVaga] = useState(false);
  const [clearFilter, setClearFilter] = useState(false);
  const [formValues, setFormValues] = useState({ cpfs: [], idVagas: [], idLocations: [], nomeFuncionario: "" });
  const [erroMessage, setErroMessage] = useState({ message: "" });
  const [order, setOrder] = useState(undefined);
  const AplicaFiltro = (form, firstPage) => {
    localStorage.setItem("filter_ListDocumentsCandidates", JSON.stringify(form));
    candidateVacancysDocs({ 
      nomeFuncionario: form.nomeFuncionario,
      cpfList: form.cpfs, 
      idVagasList: form.idVagas, 
      idLocaisList: form.idLocations, 
      idStatusList: form.idStatus, 
      dateInitActivity: form.dateInitActivity, 
      firstPage: firstPage 
    });
    setFormValues(form);
  };
  const renderIcon = (key) => {
    return orderIcon({
      style: { transform: `rotate(${order !== key ? "0" : "180deg"})` },
    });
  };
  const loadMore = () => {
    setClearFilter(false);
    AplicaFiltro(formValues, false);
  };

  const orderBy = (orderBy, list) => {

    let response;
    switch (orderBy) {
      case "cpf":
        response = orderList("cpf", order, list);
        break;
      case "nome":
        response = orderList("nome", order, list);
        break;
      case "statusCandidatoNaEtapa":
        response = orderList("statusCandidatoNaEtapa", order, list);
        break;
      case "dataUltimaAtualizacao":
        response = orderList("dataUltimaAtualizacao", order, list);
        break;
      case "nomeAprovador":
        response = orderList("nomeAprovador", order, list);
        break;
      default:
        response = { list, order: undefined };
        break;
    }
    setOrder(response.order);
  };

  useEffect(() => {
    requestInputValues(["placeOfWork"]);
    let filter_storaged = JSON.parse(localStorage.getItem("filter_ListDocumentsCandidates"));

    if (filter_storaged != null) {
      setFormValues(filter_storaged);
      candidateVacancysDocs({
        cpfList: filter_storaged.cpfs,
        idVagasList: filter_storaged.idVagas,
        idLocaisList: filter_storaged.idLocations,
        idStatusList: filter_storaged.idStatus,
        dateInitActivity: filter_storaged.dateInitActivity,
        firstPage: true,
        recoveredFilter: true,
        quantidadeItens: filter_storaged.quantidadeItens,
        nomeFuncionario: filter_storaged.nomeFuncionario,
        NumeroPagina: filter_storaged.NumeroPagina,
      });

    } else {
      candidateVacancysDocs({ cpfs: [], idVagas: [], idLocations: [], firstPage: true, nomeFuncionario: "" });
    }
  }, []);

  useEffect(() => {
    let filter_storaged = JSON.parse(localStorage.getItem("filter_ListDocumentsCandidates"));
    if (filter_storaged != null && !clearFilter) {
      filter_storaged.quantidadeItens = candidates.candidatos ? candidates.candidatos.length : 20;
      localStorage.setItem("filter_ListDocumentsCandidates", JSON.stringify(filter_storaged));
      setFormValues(filter_storaged);

    } else {
      setFormValues({ cpfs: [], idVagasList: [], idVagas: [], idLocations: [], idStatus: [], dateInitActivity: null, nomeFuncionario: "" });
    }
  }, [candidates]);

  const Filtrar = () => {
    setClearFilter(false);
    AplicaFiltro(formValues, true);
  }

  const getLocationValues = () => {

    if (formValues.idLocations) {
      return placesOfWork.filter(function (item) {
        return formValues.idLocations.indexOf(item.id) !== -1;
      }).map(e => ({
        label: e.nome,
        value: e.id
      }));
    }

    return [];
  }

  const getStatusEtapaValues = () => {

    if (formValues.idStatus) {
      return StatusEtapaDocumentos.filter(function (item) {
        return formValues.idStatus.indexOf(item.value) !== -1;
      });
    }

    return [];
  }

  const LimparFiltro = () => {
    setClearFilter(true);
    localStorage.removeItem("filter_DocumentsCandidates");
    setFormValues({});
    AplicaFiltro({}, true);
  }

  const TrataMultiInsert = Insert => {
    setErroMessage({ message: "" });
    let messages = [];
    let result = [];
    Insert.map(item => {
      let conc = item.value.trim().split(/[\s,]+/);
      conc.map(filtItem => {
        let concFiltered = filtItem.replace(/\D/g, '');
        if (concFiltered.length === 11) { 
          result.push(concFiltered);
        } else {
          messages.push({
          message:  `CPF ${filtItem} precisa ter 11 dígitos. Não será considerado no filtro.`, 
          type: "warning" });
        }
      });
    });
    messagePublication(messages);
    return result;
  };

  const RowColorStage = (status) => {

    let color;
    switch (status) {
      case "Em análise":
        color = "#f44336";
        break;
      case "Aprovado":
        color = "rgb(95 227 100)";
        break;
      case "Desistente":
        color = "#86868666";
        break;
      case "Dispensado":
        color = "#808080";
        break;
      default:
        color = "#ffeb3b";
        break;
    }
  
  
    return ({
      height: '18px',
      width: '18px',
      borderRadius: '50%',
      float: 'left',
      display: 'inline-block', backgroundColor: color
    });
  
  
  }

  const redirect = (url) => {
    window.location.href = url;
  }
  
  const requestPage = (filter) => { 
    candidateVacancysDocs({ NumeroPagina: filter.NumeroPagina});
  };

  const renderTableItems = (candidates) =>
    candidates.map(candidate => (
      <Link onClick={() => redirect(`${routes.DOCUMENTS_LIST}/${candidate.idCandidatoNaVaga}`)}>
        <TableRow>
                <TableColumn  >
            <span class="dot" style={RowColorStage(candidate.statusCandidatoNaEtapa)}></span>
          </TableColumn>
          <TableColumn ta="center">
            <Label>{candidate.cpf}</Label>
          </TableColumn>        
          <TableColumn ta="center">
            <Label>{candidate.nome}</Label>
          </TableColumn>
          <TableColumn ta="center">
            <Label>{candidate.statusCandidatoNaEtapa}</Label>
          </TableColumn>
          <TableColumn ta="center">
            <Label>{candidate.dataUltimaAtualizacao}</Label>
          </TableColumn>
          <TableColumn ta="center">
          <Label>{candidate.nomeAprovador}</Label>
          </TableColumn>
          <TableColumn ta="right" action>
            <Actions >
              <ActionItem>
                {view()}
                <LabelAction>
                  <FormattedMessage id="sharedItems.view" />
                </LabelAction>
              </ActionItem>
            </Actions>
          </TableColumn>
        </TableRow>
      </Link>
  ));
  
  const renderCandidatesTable = (candidatesList) => {
    return (
      <>
        <Table>
          <TableBody>
            <TableRow noBorder>
              <TableHead>
                <LabelHeader></LabelHeader>
              </TableHead>
              <TableHead
                onClick={() => orderBy("cpf", candidatesList.candidatos)}
                ta="center"
              >
                <LabelHeader>
                  <FormattedMessage id="documentsCandidateList.cpf" />
                </LabelHeader>
                {renderIcon("cpf")}
              </TableHead>
              <TableHead
                onClick={() => orderBy("nome", candidatesList.candidatos)}
                ta="center"
              >
                <LabelHeader>
                  <FormattedMessage id="documentsCandidateList.nomeCandidato" />
                </LabelHeader>
                {renderIcon("nome")}
              </TableHead>
              <TableHead
                onClick={() =>
                  orderBy("statusCandidatoNaEtapa", candidatesList.candidatos)
                }
                ta="center"
              >
                <LabelHeader>
                  <FormattedMessage id="documentsCandidateList.status" />
                </LabelHeader>
                {renderIcon("statusCandidatoNaEtapa")}
              </TableHead>
              <TableHead
                onClick={() =>
                  orderBy("dataUltimaAtualizacao", candidatesList.candidatos)
                }
                ta="center"
              >
                <LabelHeader>DATA APROVAÇÃO</LabelHeader>
                {renderIcon("dataUltimaAtualizacao")}
              </TableHead>
              <TableHead
                onClick={() =>
                  orderBy("nomeAprovador", candidatesList.candidatos)
                }
                ta="center"
              >
                <LabelHeader>NOME APROVADOR</LabelHeader>
                {renderIcon("nomeAprovador")}
              </TableHead>
              <TableHead ta="center">
                <LabelHeader>
                  <FormattedMessage id="documentsCandidateList.documents" />
                </LabelHeader>
              </TableHead>
            </TableRow>
            {renderTableItems(candidatesList.candidatos)}
          </TableBody>
        </Table>
        <Pagination getItemsPage={requestPage} {...pagination} pageSize={20} />
      </>
    );
  };
  
  const renderCandidatesByGroupTable = candidates =>
    candidates.candidatosPorVaga.map(candidate => (
      <Collapse
        typeStatus={0}
        position={0}
        title={"Vaga " + candidate.idVaga + " - " + candidate.nomeVaga}
      >
        <Table>
  
          <TableBody>
            <TableRow noBorder>
              <TableHead>
                <LabelHeader>
                  <FormattedMessage id="documentsCandidateList.cpf" />
                </LabelHeader>
  
              </TableHead>
              <TableHead ta="left">
                <LabelHeader>
                  <FormattedMessage id="documentsCandidateList.nomeCandidato" />
                </LabelHeader>
  
              </TableHead>
              <TableHead ta="left">
                <LabelHeader>
                  <FormattedMessage id="documentsCandidateList.documentosEnviados" />
                </LabelHeader>
  
              </TableHead>
  
              
              <TableHead ta="left">
                <LabelHeader>
                  <FormattedMessage id="documentsCandidateList.status" />
                </LabelHeader>  
                </TableHead>
  
  
                <TableHead ta="left">
                <LabelHeader>
                  DATA APROVACAO
                </LabelHeader>
  
              </TableHead>
              <TableHead ta="left">
                <LabelHeader ta="center">
                  <FormattedMessage id="documentsCandidateList.analisar" />
                </LabelHeader>
  
              </TableHead>
  
            </TableRow>
            {renderTableItems(candidate.candidatesByVacancyList)}
          </TableBody>
        </Table>
      </Collapse>
  
    ));

  if (isLoading) return <Load isLoading={isLoading} />;
  return (
    <React.Fragment>
      <BreadCrumb title={<FormattedMessage id="Sessão de Documentos" />} />
      <Body>
        <Row>
          <Col md={2}>
            <TextField
              label={<FormattedMessage id="Nome Colaborador" />}
              value={formValues.nomeFuncionario || ""}
              onChange={(e) => {
                const { value } = e.target;
                setFormValues({
                  ...formValues,
                  nomeFuncionario: value,
                });
              }}
            />
          </Col>
          <Col md={2}>
            <MultSelectCreatable
              key={5}
              name={<FormattedMessage id="documentsCandidateList.filterCpf" />}
              label={<FormattedMessage id="documentsCandidateList.filterCpf" />}
              isMulti
              options={formValues.cpfs}
              value={
                formValues.cpfs
                  ? formValues.cpfs.map((e) => ({
                      label: e,
                      value: e,
                    }))
                  : []
              }
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  cpfs: e ? TrataMultiInsert(e) : [],
                })
              }
            />
          </Col>
          <Col md={2}>
            <MultSelectCreatable
              name={
                <FormattedMessage id="documentsCandidateList.filterIdVaga" />
              }
              label={
                <FormattedMessage id="documentsCandidateList.filterIdVaga" />
              }
              isMulti
              options={
                formValues.idVagas
                  ? formValues.idVagas.map((e) => ({
                      label: e,
                      value: e,
                    }))
                  : []
              }
              value={
                formValues.idVagas
                  ? formValues.idVagas.map((e) => ({
                      label: e,
                      value: e,
                    }))
                  : []
              }
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  idVagas: e
                    ? e.map((k) => {
                        return k.value;
                      })
                    : [],
                })
              }
            />
          </Col>
          <Col md={2}>
            <MultSelect
              name={
                <FormattedMessage id="documentsCandidateList.filterLocation" />
              }
              options={placesOfWork.map((e) => ({
                label: e.nome,
                value: e.id,
              }))}
              label={
                <FormattedMessage id="documentsCandidateList.filterLocation" />
              }
              isMulti
              value={getLocationValues()}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  idLocations: e
                    ? e.map((k) => {
                        return k.value;
                      })
                    : [],
                })
              }
            />
          </Col>

          <Col md={2}>
            <MultSelect
              name={<FormattedMessage id="contracts.statusEtapa" />}
              options={StatusEtapaDocumentos}
              label={<FormattedMessage id="contracts.statusEtapa" />}
              isMulti
              value={getStatusEtapaValues()}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  idStatus: e
                    ? e.map((k) => {
                        return k.value;
                      })
                    : [],
                })
              }
            />
          </Col>
          <Col md={2}>
            <CheckBox
              onChange={() => setAgruparPorVaga(!agruparPorVaga)}
              checked={agruparPorVaga}
              label={"Agrupar por vaga"}
            />
          </Col>
        </Row>

        <Col
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "15px",
          }}
        >
          <Col md={2} style={{ marginTop: "15px" }}>
            <ButtonSecondary
              onClick={() => Filtrar()}
              name={<FormattedMessage id="sharedItems.filter" />}
            />
          </Col>
          <Col md={2} style={{ marginTop: "15px" }}>
            <ButtonSecondary
              onClick={() => LimparFiltro()}
              name={<FormattedMessage id="sharedItems.clearFilter" />}
            />
          </Col>

          <Col md={2} style={{ marginTop: "15px" }}>
            <ButtonSecondary
              onClick={() => LimparFiltro()}
              name={<FormattedMessage id="sharedItems.clearFilter" />}
            />
          </Col>
        </Col>

        {candidates.candidatos &&
          !agruparPorVaga &&
          renderCandidatesTable(candidates)}

        {agruparPorVaga && renderCandidatesByGroupTable(candidates)}
        
      </Body>
    </React.Fragment>
  );
};
const mapDispatchToProps = dispatch => ({
  candidateVacancysDocs: filter => dispatch(documentsAction.candidatesVacancysDocsRequest(filter)),
  candidateFiles: id => dispatch(documentsAction.candidateFilesRequest(id)),
  setStatusFileRequest: payload => dispatch(documentsAction.candidateSetStatusFileRequest(payload)),
  requestInputValues: inputs => dispatch(formInputsActions.formInputsRequest(inputs)),
  messagePublication: messages => dispatch(messageAction.messagePublication(messages))


});
const mapStateToProps = state => ({
  isLoading: state.documents.isLoading,
  candidates: state.documents.candidates,
  placesOfWork: state.formInputs.placeOfWork,
  hasMore: state.documents.hasMore,
  quantidadeItens: state.documents.quantidadeItens,
  pagination: state.documents.pagination,
});

export default connect(mapStateToProps, mapDispatchToProps)(ListDocumentsCandidates);

ListDocumentsCandidates.propTypes = {
  candidates: PropTypes.func,
  hasMore: PropTypes.any,
  quantidadeItens: PropTypes.any,
};

ListDocumentsCandidates.defaultProps = {
  candidates: () => { }
};
