import Draggable from "react-draggable";
import { FaCheck, FaTimes, FaEdit } from "react-icons/fa";
import theme from "../shared/Theme";
import React, { useState, useEffect, useRef } from "react";

export default function DraggablePlaceholder({ onEnd, onSet, onCancel, initialText, onDrag, locationWasSelected, setLocationWasSelected }) {
  const [text, setText] = useState("Text");
  const inputRef = useRef(null);

  useEffect(() => {
    if (initialText) {
      setText(initialText)
    } else {
      inputRef.current.focus();
      inputRef.current.select()
    }
  }, [])

  const styles = {
    container: {
      position: "absolute",
      zIndex: 100000,
      border: `1px solid ${theme.colors.defaultColor}`,
      borderRadius: 5,
      boxShadow: 'color(display-p3 0.4 0.29 0.75) 0px 0px 6px 0px',
    },
    controls: {
      position: "absolute",
      right: 0,
      display: "inline-block",
      backgroundColor: theme.colors.defaultColor,
      // borderRadius: 4,
    },
    smallButton: {
      display: "inline-block",
      cursor: "pointer",
      padding: 2,
    },
    input: {
      border: 0,
      fontSize: 17,
      padding: 3,
      paddingLeft: 13,
      paddingRight: 46,
      color: 'white',
      backgroundColor: 'rgb(108 72 197 / 84%)',
      cursor: 'move'
    }
  };
  return (
    <Draggable 
      onDrag={onDrag}
      onStop={onEnd}
      onStart={() => !locationWasSelected}
      bounds="parent"  
    >
      <div style={styles.container}>
        <div style={styles.controls}>
          <div 
            style={styles.smallButton} 
            onClick={() => {
              onSet(text);
              setLocationWasSelected(!locationWasSelected); 
            }
          }>
            {!locationWasSelected 
              ? (<FaCheck color={theme.colors.softGreen} />)
              : (<FaEdit color={theme.colors.orangeColor} />)
            }
          </div>
          <div style={styles.smallButton} onClick={onCancel} >
            <FaTimes color={theme.colors.errorColor} size={10}/>
          </div>
        </div>
        <div style={styles.input} >
          {initialText}
        </div>
      </div>
    </Draggable>
  );
}
