import {
  BodyDialog,
  BodyStyle,
  ColumnTooltip,
  FilterBody,
  FilterItemRow,
  FilterRegisterLabel,
  HeaderDialog,
  ItemFilter,
  LabelStyle,
  RowActionsButton,
  RowActionsFilter,
  RowQuestion,
  SpanDefault,
  SpanFilterValue,
  SpanQuestion,
  SpanQuestionItem,
  TextStyle,
  BodyCollapse,
  HeaderCollapse,
  TitleCollapse,
  H1,
  SelectRow,
  SelectsContainer,
  ContentCollapse,
  ButtonDefaultStyle,
  ButtonMobileStyle,
  ButtonSecondaryStyle,
  CardTitleStyle,
  CollapseDot,
  ArrowCollapse,
  ButtonContainer,
  DataInfo,
  customStylesSelect,
  LabelInput,
  InputRow,
  SimpleLabel,
} from "./CustomComponentsStyle";

import { buildDate } from "../../helpers/sharedHelpers"

import { ButtonDefault,   ButtonSecondary,  CheckBox,TextField,  SelectFieldInput, TextAreaField } from "./CustomInputs";
import React, { useEffect, useState } from "react";
import { deleteItem, filterIcon } from "../../assets/icons/icons";

import ArrowCollapseImg from "assets/icons/arrow-colapse.svg";

import { FilterRailsOptions } from "./Filters/FiltersCandidates";
import { FilterVacancyOptions } from "./Filters/FiltersVacancy";
import { FormattedMessage } from "react-intl";
import { Modal } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import { SpanToolTip } from "./CustomInputsStyle";
import { StatusStages } from "../../locale";
import Select from "react-select";
import { connect } from "react-redux";

import { Creators as messageAction } from "store/ducks/messages";

import moment from "moment";
import "moment/locale/pt-br";
import { propTypes } from "react-bootstrap/esm/Image";
import { BodyDialogInternal} from "../Candidates/DialogConfirm/DialogConfimStyle";
moment.locale("pt-BR");

export const ToolTip = ({ id, message }) => (
  <ReactTooltip styl type="light" id={id}>
    <ColumnTooltip>
      {Array.isArray(message) ? (
        message.map(e => (
          <React.Fragment>
            <SpanToolTip>
              <SpanToolTip bold>{e.split(".")[0]}.</SpanToolTip>
              {e.split(".")[1]}
            </SpanToolTip>
          </React.Fragment>
        ))
      ) : (
        <SpanToolTip>{message}</SpanToolTip>
      )}
    </ColumnTooltip>
  </ReactTooltip>
);

export const Title = ({ title, color, size, weight }) => {
  return (
    <H1 weight={weight} color={color} size={size}>
      {title}
    </H1>
  );
};
export const LabelH1 = props => (
  <LabelStyle {...props}>{props.children}</LabelStyle>
);

export const SelectInput = props => {
  return (
    <InputRow mb={props.mb} mr={props.mr} ml={props.ml} width={props.width} gridSize={props.gridSize}>
      <LabelInput ml={props.ml}>{props.label}</LabelInput>
      <Select
        placeholder={props.placeholder ? props.placeholder : ""}
        styles={customStylesSelect}
        isDisabled={props.isDisabled}
        {...props}
      />
    </InputRow>
  );
};

export const FormattedMessageWithStyle = props => (
  <FormattedMessage {...props}>
    {(txt) => (
      <span style={props.style}>
        {txt}
      </span>
    )}
  </FormattedMessage>
);

export const DataLabel = ({ text, ml, mb }) => {
  return (
    <DataInfo mb={mb} ml={ml}>
      {text}
    </DataInfo>
  );
};

export const Span = props => (
  <SpanDefault {...props}>{props.children}</SpanDefault>
);
export const Body = props => <BodyStyle>{props.children}</BodyStyle>;
export const Text = props => <TextStyle {...props}>{props.children}</TextStyle>;

export const YesOrNoDialog = ({
  show,
  question,
  confirmation,
  confirmAction,
  cancelAction
}) => (
  <Modal show={show}>
    <BodyDialog>
      <HeaderDialog>
        <RowQuestion>
          <SpanQuestion>{question}</SpanQuestion>
        </RowQuestion>
      </HeaderDialog>
      <RowQuestion>
        <SpanQuestionItem>{confirmation}</SpanQuestionItem>
      </RowQuestion>
      <RowActionsButton>
        <ButtonDefault
          onClick={() => cancelAction()}
          width="200px"
          name={<FormattedMessage id="sharedItems.no" />}
        />
        <ButtonSecondary
          onClick={() => confirmAction()}
          width="200px"
          name={<FormattedMessage id="sharedItems.yes" />}
        />
      </RowActionsButton>
    </BodyDialog>
  </Modal>
);


export const YesOrNoWithTextDialog = ({
  show,
  question,
  confirmAction,
  cancelAction,
  message,
  idConfirm,
  showMessage,
  ShowNoUntilCancel,
  ShowYesUntilCancel

}) => {
  const [instructions, setinstructions] = useState("");

  return (
  <Modal size="lg" show={show}>
    <Modal.Header>
      <LabelH1 fz="24px">{question}</LabelH1>
    </Modal.Header>
    <Modal.Body>
      <BodyDialogInternal>
        <RowQuestion style={!showMessage? {display:'none'}:{}}>

          <TextAreaField
            placeholder={"Digite aqui o motivo..."}
            value={instructions}
            onChange={e => {
              setinstructions( e.target.value)
            }}
          />
        </RowQuestion>
        <RowActionsButton>

          <ButtonDefault
            onClick={() => cancelAction()}
            width="200px"
            name={!ShowNoUntilCancel?<FormattedMessage id="sharedItems.cancel" />: "Não"}
          />
          <ButtonSecondary
            onClick={() => confirmAction(idConfirm, instructions)}
            width="200px"
            disabled = {(instructions == "" || instructions == undefined) && showMessage}
            name={!ShowYesUntilCancel?<FormattedMessage id="sharedItems.confirme" />: "Sim"}
          />
        </RowActionsButton>
      </BodyDialogInternal>
    </Modal.Body>
  </Modal>
  );
};

export const LabelComponent = ({ text, size, baseEM }) => {
  return (
    <SimpleLabel baseEM={baseEM} size={size}>
      {text}
    </SimpleLabel>
  );
};

const Filter = ({
  numberOfItems,
  kindFilter,
  onFilter,
  filterItems,
  passFilterToFather,
  messagePublication
}) => {
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [removedFilter, setremovedFilter] = useState(false);
  const [filterOptions, setFiltersOptions] = useState({});

  useEffect(() => {
    if (typeof passFilterToFather === 'function') {
      passFilterToFather(filterOptions)
    }
  }, [filterOptions])

  const removeFilter = key => {
    if (Object.keys(filterOptions).includes(key)) {
      const valuesFilter = filterOptions;
      delete valuesFilter[key];
      if (key === "range") {
        delete valuesFilter.applyStartDate;
        delete valuesFilter.applyEndDate;
      }
      const alias = key.split("N")[0];
      delete valuesFilter[alias];
      setFiltersOptions(valuesFilter);
      setremovedFilter(!removedFilter);
      onFilter(valuesFilter);
    }
  };

  const clearValues = () => {
    setFiltersOptions({});
    setremovedFilter(!removedFilter);
    setShowFilterOptions(false);
  };

  const applyFilter = () => {
    normalizeEmptyFilters();
    const errors = validateFilterOptions();
    if (errors.length) {
      const messages = errors;
      messagePublication(messages);
    } else {
      onFilter(filterOptions);
      setShowFilterOptions(false);
    }
  };

  const normalizeEmptyFilters = () => {
    Object.keys(filterOptions).map(key => {
      if (filterOptions[key] === "")
        delete filterOptions[key]
    })
  };

  const validateFilterOptions = () => {
    const errors = [];
    const startDateEmptyOrNull = filterOptions.applyStartDate === undefined || filterOptions.applyStartDate === "" || filterOptions.applyStartDate === null;
    const endDateEmptyOrNull = filterOptions.applyEndDate === undefined || filterOptions.applyEndDate === "" || filterOptions.applyEndDate === null;
    const schedulingDateEmptyOrNull = filterOptions.schedulingDate === undefined || filterOptions.schedulingDate === "" || filterOptions.schedulingDate === null;

    if (!startDateEmptyOrNull) {
      const validateApplyStartDate = moment(buildDate(filterOptions.applyStartDate)).isValid();

      if (!validateApplyStartDate)
        errors.push(`Data Inicial - APLICOU EM: (${filterOptions.applyStartDate}), data é invalida`)
    }

    if (!endDateEmptyOrNull) {
      const validateApplyEndDate = moment(buildDate(filterOptions.applyEndDate)).isValid();

      if (!validateApplyEndDate)
        errors.push(`Data Final - APLICOU EM: (${filterOptions.applyEndDate}), data é invalida`)
    }

    if (!schedulingDateEmptyOrNull) {
      const validateSchedulingDate = moment(buildDate(filterOptions.schedulingDate)).isValid();

      if (!validateSchedulingDate)
        errors.push(`Data Agendamento: (${filterOptions.schedulingDate}), data é invalida`)
    }

    if (!startDateEmptyOrNull && !endDateEmptyOrNull && filterOptions.applyStartDate >= filterOptions.applyEndDate)
      errors.push(`APLICOU EM: data de início não pode ser posterior ou igual a de fim`
    )

    const filtered = errors.filter((e, index) => {
      return errors.indexOf(e) === index;
    });

    return filtered.map((e) => ({
      type: "warning",
      message: e,
    }));
  }

  const renderKeysCandidate = key => {
    switch (key) {
      case "stageStatus":
        return <FormattedMessage id="sharedItems.status" />;

      case "code":
        return <FormattedMessage id="sharedItems.code" />;

      case "name":
        return <FormattedMessage id="sharedItems.name" />;

      case "cpf":
      case "cpfList":
        return <FormattedMessage id="sharedItems.CPF" />;

      case "enrollment":
      case "enrollmentList":
        return <FormattedMessage id="sharedItems.enrollment" />;

      default:
        return "";
    }
  };

  const renderStageStatus = (value) => {
    const status = StatusStages.filter(e => e.id == value)
    if (status.length)
      return status[0].name
    else
      return value
  };

  const renderCandidatesFilters = (key) => {
    let labelText = renderKeysCandidate(key);

    if (key === 'range')
      return renderCandidatesDateRange(filterOptions.range);

    if (key === 'stageStatus')
      return <> {labelText} {renderStageStatus(filterOptions[key])} </>;

    if (key === 'cpfList' || key === 'enrollmentList')
      return <> {labelText} {filterOptions[key].toString()} </>;

    return <> {labelText} {filterOptions[key]} </>;
  };

  const validateRangeValues = range => {
    if ((range.start !== undefined && range.start !== "") || (range.end !== undefined && range.end !== ""))
      return true

    return false
  }

  const renderCandidatesDateRange = range => {
    if (validateRangeValues(range)) {
      return (
        <>
          {range.start}
          <SpanFilterValue style={{ marginLeft: "5px", marginRight: "5px" }}>
            <FormattedMessage id="sharedItems.until" />
          </SpanFilterValue>
          {range.end}
        </>
      );
    }

    return <></>
  };

  return (
    <React.Fragment>
      <FilterItemRow showFilterOptions={showFilterOptions}>
        <div onClick={() => setShowFilterOptions(!showFilterOptions)}>
          {filterIcon()}
        </div>
        <FilterRegisterLabel>
          <FormattedMessage id="sharedItems.filter" />
        </FilterRegisterLabel>
        <FilterRegisterLabel>(</FilterRegisterLabel>
        <FilterRegisterLabel>{`${numberOfItems || 0}   `}</FilterRegisterLabel>
        <FilterRegisterLabel>
          <FormattedMessage id="sharedItems.registers" />
        </FilterRegisterLabel>
        <FilterRegisterLabel> ) </FilterRegisterLabel>

        {Object.keys(filterOptions).map(key => {
          const validRangeOptions = key === "range"
                                    ? validateRangeValues(filterOptions[key])
                                    : true;

          if (
            filterOptions[key] &&
            key !== "applyStartDate" &&
            key !== "applyEndDate" &&
            typeof filterOptions[key] !== "number" &&
            key !== "area" &&
            key !== "job" &&
            validRangeOptions
          ) {
            return (
              <ItemFilter>
                <RowActionsFilter>
                  <div
                    style={{ marginBottom: "1px" }}
                    key={key}
                    onClick={() => removeFilter(key)}
                  >
                    {deleteItem()}
                  </div>
                  <SpanFilterValue style={{ marginTop: "-2px", wordBreak: "break-word", }}>
                    { kindFilter === "candidates" ? renderCandidatesFilters(key) : filterOptions[key] }
                  </SpanFilterValue>
                </RowActionsFilter>
              </ItemFilter>
            );
          }
          return null;
        })}
      </FilterItemRow>
      {showFilterOptions && kindFilter === "vacancy" && (
        <FilterBody showFilterOptions={showFilterOptions}>
          <FilterVacancyOptions
            filterOptions={filterOptions}
            showFilterOptions={showFilterOptions}
            setFiltersOptions={setFiltersOptions}
            clearValues={clearValues}
            applyFilter={applyFilter}
            filterItems={filterItems}
          />
        </FilterBody>
      )}
      {showFilterOptions && kindFilter === "candidates" && (
        <FilterBody showFilterOptions={showFilterOptions}>
          <FilterRailsOptions
            filterOptions={filterOptions}
            showFilterOptions={showFilterOptions}
            setFiltersOptions={setFiltersOptions}
            clearValues={clearValues}
            applyFilter={applyFilter}
          />
        </FilterBody>
      )}
    </React.Fragment>
  );
};



export const Collapse = ({
  children,
  title,
  position,
  typeStatus,
  onclick
}) => {
  const [openCollapse, setOpenCollapse] = useState(false);

  useEffect(() => {
    onclick && onclick();
  }, [openCollapse]);
  return (
    <BodyCollapse openCollapse={openCollapse}>
      <HeaderCollapse onClick={() => setOpenCollapse(!openCollapse)}>
        <TitleCollapse>

          <H1 size="1.250em" color="title">
            {title}
          </H1>
        </TitleCollapse>
        <ArrowCollapse src={ArrowCollapseImg} />
      </HeaderCollapse>
      <ContentCollapse openCollapse={openCollapse}>
        {openCollapse && children}
      </ContentCollapse>
    </BodyCollapse>
  );
};

const mapDispatchToProps = (dispatch) => ({
  messagePublication: (messages) => dispatch(messageAction.messagePublication(messages))
});

const connectedFilter = connect(
  null, mapDispatchToProps
)(Filter);

export { connectedFilter as Filter }
