import styled from "styled-components";

export const Body = styled.div`
  border-radius: 3px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  padding: 30px;
`;
export const RowHeaderTable = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const Ballon = styled.span`
  height: 26px;
  border-radius: 15px;
  background-color: #ffffff;
  margin-right: 6px;
  color: #ffffff;
  text-align: center;
  margin-top: 0.5em;
  padding: 1em;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  :hover {
    cursor: pointer;
  }
  svg {
    fill: #ffffff;
  }
`;

export const BoxBallons = styled.div`
  display: flex;
  flex-wrap: wrap;
`;


export const styles = {
  container: {
    maxWidth: 900,
    margin: "0 auto",
  },
  sigBlock: {
    display: "inline-block",
    border: "1px solid grey",
  },
  containerSignature: {
    display: "flex",
    justifyContent: "space-between",
  },
  documentBlock: {
    position: 'relative',
    flex: 2,
    // maxWidth: 800,
    margin: "10px 10px 20px auto",
  },
  documentContainer: {
    boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
  },
  controls: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    maxWidth: 250,
    margin: "20% auto 10px 10px",
  },
  button: {
    marginBottom: "15px"
  }
};