import Draggable from "react-draggable";
import {BigButton} from "./BigButton"; // The default
import {FaCheck, FaTimes} from 'react-icons/fa'
import theme from "../shared/Theme";
import React, { useRef, useState, useEffect } from "react";

export default function DraggableSignature({ url, onEnd, onSet, onCancel }) {
  const styles = {
    container: {
      position: 'absolute',
      zIndex: 100000,
      border: `2px solid ${theme.colors.defaultColor}`,
    },
    controls: {
      position: 'absolute',
      right: 0,
      display: 'inline-block',
      backgroundColor: theme.colors.defaultColor,
      // borderRadius: 4,
    },
    smallButton: {
      display: 'inline-block',
      cursor: 'pointer',
      padding: 4,
    }
  }

  useEffect(() => {
    //console.log('onEnd', onEnd);
    //console.log('onSet', onSet);
  }, [onEnd, onSet])
  

  return (
    <Draggable 
      onStop={onEnd}
      bounds="parent"
    >
      <div style={styles.container}>
        <div style={styles.controls}>
          <div style={styles.smallButton} onClick={onSet}><FaCheck color={theme.colors.softGreen}/></div>
          <div style={styles.smallButton} onClick={onCancel}><FaTimes color={theme.colors.errorColor}/></div>
        </div>
        <img src={url} width={200} style={styles.img} draggable={false} />
      </div>
    </Draggable>
  );
}
