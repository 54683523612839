import React, { useEffect, useState, useRef } from "react";
import { Body, styles } from "./UploadDocumentsSignatureStyle";
import BreadCrumb from "components/Layout/breadcrumb";
import { ENVIAR_DOCUMENTS_CANDIDATES } from "../../../../../utils/contants/urls";
import { push } from "connected-react-router";
import { useLocation } from 'react-router-dom';
import Load from "components/Layout/Load";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Creators as documentsAction } from "store/ducks/documents";
import Drop from "./Drop";
import { Document, Page, pdfjs } from "react-pdf";
import { PDFDocument, rgb } from "pdf-lib";
import { blobToURL } from "../../../../../utils/files/files";
import PagingControl from "components/DocumentSignature/PagingControl";
import { AddSigDialog } from "components/DocumentSignature/AddSigDialog";
import { ButtonDefault, ButtonSecondary } from "../../../../../components/shared/CustomInputs";
import DraggableSignature from "components/DocumentSignature/DraggableSignature";
import DraggableText from "components/DocumentSignature/DraggableText";
import DraggablePlaceholder from "components/DocumentSignature/DraggablePlaceholder";
import moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-BR");

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function downloadURI(uri, name) {
  var link = document.createElement("a");
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const ListUploadDocuments = ({
  isLoading,
  location,
  requestCandidateIncludeNewDoc,
}) => {
  const [pdf, setPdf] = useState(null);
  const [autoDate, setAutoDate] = useState(true);
  const [signatureURL, setSignatureURL] = useState(null);
  const [position, setPosition] = useState(null);
  const [signatureDialogVisible, setSignatureDialogVisible] = useState(false);
  const [textInputVisible, setTextInputVisible] = useState(false);
  const [placeholderVisible, setPlaceholderVisible] = useState(false);
  const [pageNum, setPageNum] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageDetails, setPageDetails] = useState(null);
  const [file, setFile] = useState(null);
  const [locationWasSelected, setLocationWasSelected] = useState(false);
  const [formValues, setFormValues] = useState({
    idDocumento: null,
    idCandidato: null,
    mesReferencia: null,
  });
  const [deltaPosition, setDeltaPosition] = useState({
    x: 0,
    y: 0
  });

  const documentRef = useRef(null);
  const { search } = useLocation();

  const handleDrag = (e, ui) => {
    const {x, y} = deltaPosition;
    setDeltaPosition({
      x: x + ui.deltaX,
      y: y + ui.deltaY,
    });
  };

  useEffect(() => {
    const query = new URLSearchParams(search);
    const candidatoId = query.get("candidato");
    const documentoId = query.get("documento");
    const mesReferencia = query.get("mesReferencia");
    
    setFormValues({
      ...formValues,
      idDocumento: documentoId,
      idCandidato: candidatoId,
      mesReferencia: mesReferencia,
    });

  }, [search]);
  

  const token = localStorage.getItem("Authorization");

  const uploadDocument = (values) => {
    requestCandidateIncludeNewDoc(values);
  };

  if (isLoading) return <Load isLoading={isLoading} />;
  return (
    <React.Fragment>
      <BreadCrumb
        location={location}
        title={"Status documentação"}
      />
      <Body>
        <div style={styles.container}>
          {signatureDialogVisible ? (
            <AddSigDialog
              autoDate={autoDate}
              setAutoDate={setAutoDate}
              onClose={() => setSignatureDialogVisible(false)}
              onConfirm={(url) => {
                setSignatureURL(url);
                setSignatureDialogVisible(false);
              }}
            />
          ) : null}

          {!pdf ? (
            <Drop
              onLoaded={async (files) => {
                setFile(files[0]);
                const URL = await blobToURL(files[0]);
                setPdf(URL);
              }}
            />
          ) : null}
          {pdf ? (
            <div style={styles.containerSignature}>
              <div style={styles.documentBlock}>
                <div style={styles.documentContainer}>
                  {placeholderVisible ? (
                    <DraggablePlaceholder
                      initialText={"assinatura"}
                      onCancel={() => {
                        setPlaceholderVisible(false);  
                        setLocationWasSelected(false);
                      }}
                      //onDrag={handleDrag}
                      locationWasSelected={locationWasSelected}
                      setLocationWasSelected={setLocationWasSelected}
                      onEnd={setPosition}
                      onSet={async (text) => {
                        const { originalHeight, originalWidth } = pageDetails;
                        const scale = originalWidth / documentRef.current.clientWidth;
                      
                        const newY = position.layerY - documentRef.current.offsetTop;
                        const newX = position.layerX - documentRef.current.offsetLeft;
                      
                        setDeltaPosition({
                          x: Math.round(newX * scale),
                          y: Math.round(newY * scale),
                        });
                      }}
                    />
                  ) : null}
                  {textInputVisible ? (
                    <DraggableText
                      initialText={
                        textInputVisible === "date"
                          ? moment().format("DD/MM/YYYY")
                          : null
                      }
                      onCancel={() => setTextInputVisible(false)}
                      onEnd={setPosition}
                      onSet={async (text) => {
                        const { originalHeight, originalWidth } = pageDetails;
                        const scale = originalWidth / documentRef.current.clientWidth;
                      
                        const newY = originalHeight - (position.layerY - documentRef.current.offsetTop)
                        const newX = position.layerX - documentRef.current.offsetLeft; // Use layerX and offsetLeft
                      
                        const pdfDoc = await PDFDocument.load(pdf);
                        const pages = pdfDoc.getPages();
                        const firstPage = pages[pageNum];
                      
                        firstPage.drawText(text, {
                          x: newX * scale, // Apply scale to x coordinate
                          y: newY * scale, // Apply scale to y coordinate
                          size: 20 * scale,
                        });
                      
                        const pdfBytes = await pdfDoc.save();
                        const blob = new Blob([new Uint8Array(pdfBytes)]);
                        const URL = await blobToURL(blob);
                        setPdf(URL);
                        setPosition(null);
                        setTextInputVisible(false);   
                      
                      }}
                    />
                  ) : null}
                  {signatureURL ? (
                    <DraggableSignature
                      url={signatureURL}
                      onCancel={() => {
                        setSignatureURL(null);
                      }}
                      onSet={async () => {
                        const { originalHeight, originalWidth } = pageDetails;
                        const scale = originalWidth / documentRef.current.clientWidth;
                      
                        const newY = originalHeight - (position.layerY - documentRef.current.offsetTop)
                        const newX = position.layerX - documentRef.current.offsetLeft; // Use layerX and offsetLeft
                      
                        const pdfDoc = await PDFDocument.load(pdf);
                        const pages = pdfDoc.getPages();
                        const firstPage = pages[pageNum];

                        const pngImage = await pdfDoc.embedPng(signatureURL);
                        const pngDims = pngImage.scale( scale * .3);

                        firstPage.drawImage(pngImage, {
                          x: newX * scale,
                          y: newY * scale,
                          width: pngDims.width,
                          height: pngDims.height,
                        });

                        if (autoDate) {
                          firstPage.drawText(
                            `Assinado ${moment().format(
                              "DD/MM/YYYY HH:mm:ss ZZ"
                            )}`,
                            {
                              x: newX * scale,
                              y: newY * scale - 10,
                              size: 14 * scale,
                              color: rgb(0.074, 0.545, 0.262),
                            }
                          );
                        }

                        const pdfBytes = await pdfDoc.save();
                        const blob = new Blob([new Uint8Array(pdfBytes)]);

                        const URL = await blobToURL(blob);
                        setPdf(URL);
                        setPosition(null);
                        setSignatureURL(null);
                      }}
                      onEnd={setPosition}
                    />
                  ) : null}
                  <div ref={documentRef}>
                    <Document
                      file={pdf}
                      onLoadSuccess={(data) => {
                        setTotalPages(data.numPages);
                      }}
                    >
                      <Page
                        pageNumber={pageNum + 1}
                        onLoadSuccess={(data) => {
                          setPageDetails(data);
                        }}
                      />
                    </Document>
                  </div>
                </div>
                <PagingControl
                  pageNum={pageNum}
                  setPageNum={setPageNum}
                  totalPages={totalPages}
                />
              </div>
              <div style={styles.controls}>
                {!signatureURL ? (
                  <ButtonSecondary
                    disabled={isLoading}
                    isLoading={isLoading}
                    onClick={() => setPlaceholderVisible(true)}
                    name={"Adicionar assinatura"}
                    style={styles.button}
                  />
                ) : null}
                {/*{!signatureURL ? (
                  <BigButton
                    marginRight={8}
                    title={"Adicionar assinatura"}
                    onClick={() => setSignatureDialogVisible(true)}
                  />
                ) : null}

                <BigButton
                  marginRight={8}
                  title={"Adicionar data"}
                  onClick={() => setTextInputVisible("date")}
                />
                <BigButton
                  marginRight={8}
                  title={"Adicionar texto"}
                  onClick={() => setTextInputVisible(true)}
                />
                */}
                <ButtonSecondary
                  disabled={isLoading}
                  isLoading={isLoading}
                  onClick={() => {
                    setTextInputVisible(false);
                    setSignatureDialogVisible(false);
                    setSignatureURL(null);
                    setPdf(null);
                    setTotalPages(0);
                    setPageNum(0);
                    setPageDetails(null);
                    setDeltaPosition({x: 0, y:0})
                  }}
                  name={"Resetar"}
                  style={styles.button}
                />
                {pdf ? (
                  <ButtonDefault
                    disabled={isLoading || !locationWasSelected || !placeholderVisible}
                    isLoading={isLoading}
                    onClick={() => {
                      uploadDocument({ file, ...formValues, delta: {...deltaPosition}, pagina: pageNum });
                      // downloadURI(pdf, "arquivo_assinado.pdf");
                    }}
                    name={"Salvar"}
                    style={styles.button}
                  />
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
      </Body>
    </React.Fragment>
  );
};
const mapDispatchToProps = dispatch => ({
  requestCandidateIncludeNewDoc: payload => dispatch(documentsAction.candidateIncludeNewDocToSignRequest(payload)),
  redirectToDocuments: id => dispatch(push(`${ENVIAR_DOCUMENTS_CANDIDATES}`)),
});
const mapStateToProps = state => ({
  isLoading: state.documents.isLoading,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListUploadDocuments);

ListUploadDocuments.propTypes = {
  filesCandidateSelected: PropTypes.func
};

ListUploadDocuments.defaultProps = {
  filesCandidateSelected: () => { }
};
