import * as urls from "utils/contants/urls";

import Form from 'react-bootstrap/Form';

import {
  ActionItem,
  Actions,
  Label,
  LabelAction,
  LabelHeader,
  Table,
  TableBody,
  TableColumn,
  TableHead,
  TableRow,
} from "components/shared/CustomComponentsStyle";
import React, { useEffect, useState } from "react";
import { push } from "connected-react-router";
import { Col, Row } from "react-bootstrap";
import {
  order,
  statusFinishedFill,
  statusOkFill,
  view,
  order as orderIcon,
  nextPage,
  previousPage,
  forwardTenPages,
  backTenPages
} from "assets/icons/icons";

import {orderList,
        orderListDescending} from "helpers/sharedHelpers";
import { StatusDocuments } from "utils/contants/enums";
import { Creators as formInputsActions } from "store/ducks/formInputs";
import { Body } from "./ListCandidatesStyle";
import BreadCrumb from "components/Layout/breadcrumb";
import {
  MultSelectCreatable,
  MultSelect,
  SelectFieldInput,
  CheckBox,
  ButtonSecondary,
  TextField,
} from "components/shared/CustomInputs";
import {
  Filter,
  Collapse,
  SelectInput,
  DataLabel,
} from "components/shared/CustomComponents";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import Load from "components/Layout/Load";
import Pagination from "components/Pagination";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import InputMask from "react-input-mask";
import { Creators as documentsAction } from "store/ducks/documents";
import { Creators as candidatesAction } from "store/ducks/candidates";
import { StatusEtapaDocumentos } from "utils/contants/enums";

import { Creators as messageAction } from "store/ducks/messages";
import { id } from "@hapi/joi/lib/base";


const redirect = (url) => {
  window.location.href = url;
};


const ListAssinaturaPendente = ({
  getCandidateList,
  candidates,
  isLoading,
  pagination,
  location,
  requestInputValues,
  placesOfWork,
  messagePublication,
  hasMore,
  goTo,
  releaseCancellationForCandidatesRequest,
  releasePasswordReset,
  requestList
}) => {
  const [agruparPorVaga, setAgruparPorVaga] = useState(false);
  const [clearFilter, setClearFilter] = useState(false);
  const [formValues, setFormValues] = useState({
    cpfs: [],
    idVagas: [],
    idLocations: [],
    nomeFuncionario: "",
  });
  const [filterItems, setFilterItems] = useState({ idArea: null, idCargo: null });
  const [erroMessage, setErroMessage] = useState({ message: "" });
  const [contextCandidates, setContextCandidates] = useState([]);
  const [order, setOrder] = useState(undefined);
  const AplicaFiltro = (form, firstPage) => {
    localStorage.setItem("filter_ListAssinaturaPendente", JSON.stringify(form));

    getCandidateList({ ...form, firstPage });
    setFormValues(form);
  };

  const renderIcon = (key) => {
    return orderIcon({
      style: { transform: `rotate(${order !== key ? "0" : "180deg"})` },
    });
  };

  const selectCandidate = id => {

    const dupContextList = contextCandidates.map(item => {
      if (item.id === id) {
        item.selected = !item.selected;
      }
      return item;
    });
    setContextCandidates(dupContextList);
  };
 


  const loadMore = () => {
    setClearFilter(false);
    const filter_storaged = JSON.parse(
      localStorage.getItem("filter_ListAssinaturaPendente")
    );
    if (filter_storaged != null) {
      filter_storaged.NumeroPagina += filter_storaged.NumeroPagina;
      const filterFormValues = {
        ...filter_storaged,
        recoveredFilter: true,
        firstPage: false
      };
      setFormValues({...filterFormValues});
      localStorage.setItem("filter_ListAssinaturaPendente", JSON.stringify(formValues));
      AplicaFiltro(filterFormValues, false);
    } else {
      AplicaFiltro(formValues, false);
    }
  };

  const orderBy = (orderBy, list) => {
    let response;
    switch (orderBy) {
      case "cpf":
        response = orderList("cpf", order, list);
        break;
      case "nome":
        response = orderList("nome", order, list);
        break;
      case "admConcluiuAdmissao":
        response = orderList("admConcluiuAdmissao", order, list);
        break;
      case "dataConclusaoAdmissao":
        response = orderList("dataConclusaoAdmissao", order, list);
        break;
      case "email":
        response = orderList("email", order, list);
        break;
      default:
        response = { list, order: undefined };
        break;
    }
    setOrder(response.order);
  };

  useEffect(() => {
    requestInputValues(["placeOfWork"]);
    let filter_storaged = JSON.parse(
      localStorage.getItem("filter_ListAssinaturaPendente")
    );

    if (filter_storaged != null) {
      
      setFormValues({
        ...filter_storaged,
        firstPage: true,
        recoveredFilter: true,
      });

     getCandidateList({...formValues, recoveredFilter: true, firstPage: true, quantidadeItens: filter_storaged.quantidadeItens});
    } else {
      getCandidateList({ ...formValues, NumeroPagina: 1, firstPage: true });
    } 
  }, []);

 
  useEffect(() => {
    
    if (candidates.candidates != undefined && candidates.candidates.length) {
      const dupContextList = candidates.candidates.map(candidate => {
        return { ...candidate, selected: false};
      });

      setContextCandidates(dupContextList);
    }
    let filter_storaged = JSON.parse(
      localStorage.getItem("filter_ListAssinaturaPendente")
    );
    if (filter_storaged != null && !clearFilter) {
      filter_storaged.quantidadeItens = candidates.candidates
        ? candidates.candidates.length
        : 20;
      localStorage.setItem(
        "filter_ListAssinaturaPendente",
        JSON.stringify(filter_storaged)
      );
      setFormValues(filter_storaged);
    } else {
      setFormValues({
        cpfs: [],
        idVagasList: [],
        idStatus: [],
        firstPage: true,
        
      });
    }
  }, [candidates, setContextCandidates]);

  const requestPage = (filter) => { 

    getCandidateList({ ...filterItems, NumeroPagina: filter.NumeroPagina});
  };

  const Filtrar = () => {
    setClearFilter(false);
    AplicaFiltro(formValues, true);
  };

  const getLocationValues = () => {
    if (formValues.idLocations) {
      return placesOfWork
        .filter(function (item) {
          return formValues.idLocations.indexOf(item.id) !== -1;
        })
        .map((e) => ({
          label: e.nome,
          value: e.id,
        }));
    }

    return [];
  };

  const getStatusEtapaValues = () => {
    if (formValues.idStatus) {
      return StatusEtapaDocumentos.filter(function (item) {
        return formValues.idStatus.indexOf(item.value) !== -1;
      });
    }

    return [];
  };

  const LimparFiltro = () => {
    setClearFilter(true);
    localStorage.removeItem("filter_DocumentsCandidates");
    setFormValues({});
    AplicaFiltro({}, true);
  };
 

  const cancelarCandidatos = () => {
    const selectedCandidates = contextCandidates.filter(candidate => candidate.selected).map(candidate => { return candidate.id });
    releaseCancellationForCandidatesRequest({idsCandidatosEtapa: selectedCandidates});
  };

  const resetarSenhaCandidato = () => {
    const selectedCandidates = contextCandidates.filter(candidate => candidate.selected).map(candidate => { return candidate.id });
    releasePasswordReset(selectedCandidates);
  };

  const TrataMultiInsert = (Insert) => {
    setErroMessage({ message: "" });
    let messages = [];
    let result = [];
  
    Insert.map((item) => {
      let conc = item.value.trim().split(/[\s,]+/);
      conc.map((filtItem) => {
        let concFiltered = filtItem.replace(/\D/g, ''); 
        if (concFiltered.length === 11) {
          result.push(concFiltered);
        } else {
          messages.push({
            message: `CPF ${filtItem} precisa ter 11 dígitos. Não será considerado no filtro.`,
            type: "warning",
          });
        }
      });
    });
  
    messagePublication(messages);
    return result;
  };


const renderTableItems = (candidates) =>{
return candidates.map((candidate) => (
  <TableRow key={candidate.id}>
    <TableColumn ta="center">
      <CheckBox
        onChange={() => selectCandidate(candidate.id)}
        checked={candidate.selected}
        style={{ margin: "1px" }}
      />
      <Label>{candidate.code}</Label>
    </TableColumn>
    <TableColumn ta="center">
      <Label>{candidate.cpf}</Label>
    </TableColumn>
    <TableColumn ta="center">
      <Label>{candidate.nome}</Label>
    </TableColumn>
    <TableColumn ta="center">
      <Label>{candidate.email}</Label>
    </TableColumn>
    <TableColumn ta="center" action>
      <Actions flexPosition="flex-start">
        <ActionItem
          onClick={() =>
            redirect(`${urls.PLUG_EDIT_CANDIDATE}/${candidate.id}`)
          }
        >
          {view()}
          <LabelAction>
            <FormattedMessage id="sharedItems.view" />
          </LabelAction>
        </ActionItem>
      </Actions>
    </TableColumn>
  </TableRow>
));}

const renderCandidatesTable = (candidatesList) => {
  return (
    <>
      <Table>
        <TableBody>
          <TableRow noBorder>
            <TableHead style={{ textAlign: "center" }}>
              <LabelHeader>Selecionar</LabelHeader>
            </TableHead>
            <TableHead
              onClick={() => orderBy("cpf", contextCandidates)}
              style={{ textAlign: "center" }}
            >
              <LabelHeader>
                <FormattedMessage id="plugCandidatesList.cpf" />
              </LabelHeader>
              {renderIcon("cpf")}
            </TableHead>
            <TableHead
              onClick={() => orderBy("nome", contextCandidates)}
              style={{ textAlign: "center" }}
            >
              <LabelHeader>
                <FormattedMessage id="plugCandidatesList.nomeCandidato" />
              </LabelHeader>
              {renderIcon("nome")}
            </TableHead>
            <TableHead
              onClick={() => orderBy("email", contextCandidates)}
              style={{ textAlign: "center" }}
            >
              <LabelHeader>
                <FormattedMessage id="plugCandidatesList.email" />
              </LabelHeader>
              {renderIcon("email")}
            </TableHead>
            <TableHead style={{ textAlign: "center" }}>
              <LabelHeader>
                <FormattedMessage id="plugCandidatesList.analisar" />
              </LabelHeader>
            </TableHead>
          </TableRow>
          {renderTableItems(contextCandidates)}
        </TableBody>
      </Table>
      <Pagination getItemsPage={requestPage} {...pagination} pageSize={20} />
    </>
  );
};

const renderCandidatesByGroupTable = (candidates) =>
  candidates.candidatesPorVaga.map((candidate) => (
    <Collapse
      typeStatus={0}
      position={0}
      title={`Vaga ${candidate.idVaga} - ${candidate.nomeVaga}`}
    >
      <Table>
        <TableBody>
          <TableRow noBorder>
            
            <TableHead>
              <LabelHeader>
                <FormattedMessage id="plugCandidatesList.cpf" />
              </LabelHeader>
            </TableHead>
            <TableHead ta="left">
              <LabelHeader>
                <FormattedMessage id="plugCandidatesList.nomeCandidato" />
              </LabelHeader>
            </TableHead>
            <TableHead ta="left">
              <LabelHeader>
                <FormattedMessage id="plugCandidatesList.status" />
              </LabelHeader>
            </TableHead>
            <TableHead ta="left">
              <LabelHeader>
                <FormattedMessage id="plugCandidatesList.email" />
              </LabelHeader>
            </TableHead>
            <TableHead ta="left">
              <LabelHeader ta="center">
                <FormattedMessage id="plugCandidatesList.analisar" />
              </LabelHeader>
            </TableHead>
          </TableRow>
          {renderTableItems(candidate.candidatesByVacancyList)}
        </TableBody>
      </Table>
    </Collapse>
  ));
 

  if (isLoading) return <Load isLoading={isLoading} />;
  return (
    <React.Fragment>
      <BreadCrumb  title="Selecione o colaborador" />
      <Body>
        <Row style={{display:"flex"}}>
          <Col md={3}>
            <MultSelectCreatable 
              key={5}
              name={<FormattedMessage id="documentsCandidateList.filterCpf" />}
              label={<FormattedMessage id="documentsCandidateList.filterCpf" />}
              isMulti
              options={formValues.cpfs}
              value={
                formValues.cpfs
                  ? formValues.cpfs.map((e) => ({
                      label: e,
                      value: e,
                    }))
                  : []
              }
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  cpfs: e ? TrataMultiInsert(e) : [],
                })
              }
            />
          </Col>
          <Col md={3}>
          <TextField
            label={<FormattedMessage id="Nome Colaborador" />}
            value={formValues.nomeFuncionario || ""}
            onChange={(e) => {
              const { value } = e.target;
              setFormValues({
                ...formValues,
                nomeFuncionario: value,
              });
            }}
          />
          </Col>
            <Col md={2} style={{ marginTop: "15px" }}>
            <ButtonSecondary
              onClick={() => Filtrar()}
              name={<FormattedMessage id="sharedItems.filter" />}
            />
          </Col>
          <Col md={2} style={{ marginTop: "15px"}}>
            <ButtonSecondary
              onClick={() => LimparFiltro()}
              name={<FormattedMessage id="sharedItems.clearFilter" />}
            />
          </Col>
        </Row>
        <Row style={{display:"flex", marginBottom:"15px"}}>
          <Col md={3}  style={{ marginTop: "15px" }}>
            <ButtonSecondary
              onClick={() => goTo(urls.PLUG_NEW_CANDIDATE)}
              name={<FormattedMessage id="sharedItems.newCandidate" />}
            />
          </Col>
          <Col md={3} style={{ marginTop: "15px" }}>
            <ButtonSecondary
                disabled={!contextCandidates.some(candidate => candidate.selected)}
              onClick={() => cancelarCandidatos()}
              name="Desativar Colaborador"
            />
          </Col>
          <Col md={3} style={{ marginTop: "15px" }}>
            <ButtonSecondary
              disabled={!contextCandidates.some(candidate => candidate.selected)}
              onClick={() => resetarSenhaCandidato()}
              name="Resetar Senha"
            />
          </Col>
          </Row>

        {candidates.candidates && renderCandidatesTable(candidates)}
          
        {agruparPorVaga && renderCandidatesByGroupTable(candidates)}
       
      </Body>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getCandidateList: (filter) =>
    dispatch(candidatesAction.candidatesGetCandidatesListRequest(filter)),
  requestInputValues: (inputs) =>
    dispatch(formInputsActions.formInputsRequest(inputs)),
  messagePublication: (messages) =>
    dispatch(messageAction.messagePublication(messages)),
  goTo: (route) => dispatch(push(route)),
  releaseCancellationForCandidatesRequest: payload => dispatch(candidatesAction.releaseCancellationForCandidatesRequest(payload)),
  releasePasswordReset: payload => dispatch(candidatesAction.candidatesPasswordResetRequest(payload)),

});

const mapStateToProps = (state) => ({
  candidates: state.candidates,
  isLoading: state.candidates.isLoading,
  placesOfWork: state.formInputs.placeOfWork,
  hasMore: state.documents.hasMore,
  quantidadeItens: state.documents.quantidadeItens,
  pagination: state.candidates.pagination,
});

export default connect(mapStateToProps, mapDispatchToProps)(ListAssinaturaPendente);

ListAssinaturaPendente.propTypes = {
  candidates: PropTypes.func,
  hasMore: PropTypes.any,
  quantidadeItens: PropTypes.any,
};

ListAssinaturaPendente.defaultProps = {
  candidates: () => {},
};
