import * as urls from "utils/contants/urls";
import {
  ActionItem,
  Actions,
  Label,
  LabelAction,
  LabelHeader,
  Table,
  TableBody,
  TableColumn,
  TableHead,
  TableRow,
} from "components/shared/CustomComponentsStyle";
import theme from "../../../../../components/shared/Theme.js";

import { push } from "connected-react-router";
import React, { useEffect, useRef, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { editIcon } from "assets/icons/icons";
import { trashIcon } from "assets/icons/icons";
import BreadCrumb from "components/Layout/breadcrumb";

import {
  SelectFieldInput,
  TextAreaField,
  ButtonDefault,
  ButtonSecondary,
  MultSelect,
  TextField,
} from "components/shared/CustomInputs";

import { FormattedMessage } from "react-intl";
import Load from "components/Layout/Load";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Creators as contractsAction } from "store/ducks/contracts";
import { Creators as userBenefits } from "store/ducks/userBenefits";

import {
  YesOrNoWithTextDialog,
  Title,
  YesOrNoDialog,
} from "components/shared/CustomComponents";
import { Body, EmptyTable } from "./DetailContractCandidateStyle";

const DetailBenefitsCandidate = ({
  match,
  isLoading,
  location,
  redirectToContracts,
  beneficios,
  userBenefitsById,
  removerValeTransporte,
  AtualizarValeTransporte,
  inativarBeneficios,
  AtualizarValeRefeicao,
  RemoverValeRefeicao,
}) => {

  useEffect(() => {
    const { params } = match;
    
    if (params.id) {
      userBenefitsById({ id: params });
      setUserId(params.id);
    }
  }, []);

  const [userId, setUserId] = useState(0);

  const [showDialogYesOrNo, setShowDialogYesOrNo] = useState(false);
  const [showDialogNewFileYesOrNo, setshowDialogNewFileYesOrNo] =
    useState(false);
  const token = localStorage.getItem("Authorization");

  const [indiceBeneficioEditar, setIndiceBeneficioEditar] = useState(0);
  const hiddenFileInput = React.createRef();

  const canShowDialog = (idDoc) => {
    return showDialogYesOrNo == idDoc;
  };

  const [showDialog, setShowDialog] = useState(false);
  const [showDialogValeRefeicao, setShowDialogValeRefeicao] = useState(false);

  //remoção de beneficios:
  const [BeneficioParaRemocao, setBeneficioParaRemocao] = useState({});
  const [TipoRemocao, setTipoRemocao] = useState("");

  const formatCurrency = (value) => {
    const numericValue =
      typeof value === "string"
        ? parseFloat(value.replace(/[^0-9]/g, "")) / 100
        : value;
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(numericValue);
  };

  const renderFilesCandidatesTable = (
    candidateDocs,
    setStatusFile,
    token,
    canShowDialog,
    showDialogYesOrNo,
    setShowDialogYesOrNo,
    userId,
    setBeneficioParaRemocao,
    setTipoRemocao
  ) => {
    return (
      <div>
        <br />
        <Row>
          <Col md={4}>
            <label style={{ fontWeight: "bold", color: "#005394" }}>
              Nome:&nbsp;
            </label>
            <Label bold>{candidateDocs.candidatoNome}</Label>
          </Col>
          <Col md={2}>
            <label style={{ fontWeight: "bold", color: "#005394" }}>
              CPF:&nbsp;
            </label>
            <Label bold>{candidateDocs.cpf}</Label>
          </Col>
          <Col md={3}>
            <label style={{ fontWeight: "bold", color: "#005394" }}>
              Dias Úteis:&nbsp;
            </label>
            <Label bold>{candidateDocs.diasUteis}</Label>
          </Col>
          <Col md={3} style={{ marginBottom: "-5px" }}>
            <label style={{ fontWeight: "bold", color: "#005394" }}>
              Ausências:&nbsp;
            </label>
            <Label bold>{candidateDocs.ausencias}</Label>
          </Col>
          <Col md={2} style={{ marginBottom: "-17px" }}></Col>
        </Row>
        <br />
        <Table>
          <TableBody>
            <TableRow noBorder>
              <TableHead style={{ width: "5px" }}>
                <LabelHeader>ID</LabelHeader>
              </TableHead>
              <TableHead ta="left">
                <LabelHeader>Empresa Transportadora</LabelHeader>
              </TableHead>
              <TableHead ta="left">
                <LabelHeader>Quantidade de Passagens</LabelHeader>
              </TableHead>
              <TableHead ta="left">
                <LabelHeader>Valor da Passagem</LabelHeader>
              </TableHead>
            </TableRow>
            {candidateDocs.valeTransporte.length > 0
              ? renderTableValeTransporte(
                  beneficios,
                  userBenefitsById,
                  setShowDialogYesOrNo,
                  showDialogYesOrNo,
                  userId,
                  removerValeTransporte,
                  setBeneficioParaRemocao,
                  setTipoRemocao
                )
              : "Não possui referencia de vale transporte"}
          </TableBody>
        </Table>
        <br />
      </div>
    );
  };

  const rendertableValeRefeicao = (
    candidateDocs,
    setStatusFile,
    token,
    canShowDialog,
    showDialogYesOrNo,
    setShowDialogYesOrNo,
    userId,
    setBeneficioParaRemocao,
    setTipoRemocao
  ) => {
    return (
      <div>
        <br />
        <br />
        <Table>
          <TableBody>
            <TableRow noBorder>
              <TableHead style={{ width: "5px" }}>
                <LabelHeader>ID</LabelHeader>
              </TableHead>
              <TableHead ta="center">
                <LabelHeader>Vale Refeição</LabelHeader>
              </TableHead>
            </TableRow>
            {candidateDocs.valeRefeicao > 0
              ? tabelaValeRefeicao(
                  beneficios,
                  userBenefitsById,
                  setShowDialogYesOrNo,
                  showDialogYesOrNo,
                  userId,
                  setBeneficioParaRemocao,
                  setTipoRemocao
                )
              : "Não possui referencia de vale refeição"}
          </TableBody>
        </Table>
        <br />
      </div>
    );
  };

  const renderTableValeTransporte = (
    beneficios,
    userBenefitsById,
    showDialogYesOrNo,
    setShowDialogYesOrNo,
    removerValeTransporte,
    userId,
    setBeneficioParaRemocao,
    setTipoRemocao
  ) =>
    beneficios.valeTransporte.map((file, index) => (
      <TableRow>
        <TableColumn ta="left">
          <Label>{file.id}</Label>
        </TableColumn>
        <TableColumn ta="left">
          <Label>{file.nome}</Label>
        </TableColumn>
        <TableColumn ta="left ">
          <Label>{file.quantidade}</Label>
        </TableColumn>
        <TableColumn ta="left">
          <Label>{formatCurrency(file.valor)}</Label>
        </TableColumn>
        <TableColumn ta="right" action>
          <Actions
            flexPosition="flex-start"
            onClick={() => {
              setShowDialog(true);
              setIndiceBeneficioEditar(index);
            }}
            style={{ cursor: "progress" }}
          >
            <ActionItem>
              {editIcon()}
              <LabelAction>
                <FormattedMessage id="Editar" />
              </LabelAction>
            </ActionItem>
          </Actions>
        </TableColumn>
        <Actions
          flexPosition="flex-start"
          onClick={() => {
            setBeneficioParaRemocao(file);
            setTipoRemocao("ValeTransporte");
            setShowDialogYesOrNo(true);
          }}
          style={{ cursor: "progress" }}
        >
          <ActionItem>
            {trashIcon()}
            <LabelAction>
              <FormattedMessage id="Remover" />
            </LabelAction>
          </ActionItem>
        </Actions>
        <TableColumn ta="right" action></TableColumn>
      </TableRow>
    ));

  const tabelaValeRefeicao = (
    beneficios,
    userBenefitsById,
    showDialogYesOrNo,
    setShowDialogYesOrNo,
    userId,
    setBeneficioParaRemocao,
    setTipoRemocao
  ) => (
    <TableRow>
      <TableColumn ta="left">
        <Label>{beneficios.id}</Label>
      </TableColumn>
      <TableColumn ta="center">
        <Label>{formatCurrency(beneficios.valeRefeicao)}</Label>
      </TableColumn>
      <TableColumn ta="right" action>
        <Actions
          flexPosition="flex-start"
          onClick={() => {
            setShowDialogValeRefeicao(true);
            setIndiceBeneficioEditar(beneficios.id);
          }}
          style={{ cursor: "progress" }}
        >
          <ActionItem>
            {editIcon()}
            <LabelAction>
              <FormattedMessage id="Editar" />
            </LabelAction>
          </ActionItem>
        </Actions>
      </TableColumn>
      <TableColumn ta="right" action>
        <Actions
          flexPosition="flex-start"
          onClick={() => {
            setBeneficioParaRemocao(beneficios);
            setTipoRemocao("ValeRefeicao");
            setShowDialogYesOrNo(true);
          }}
          style={{ cursor: "progress" }}
        >
          <ActionItem>
            {trashIcon()}
            <LabelAction>
              <FormattedMessage id="Remover" />
            </LabelAction>
          </ActionItem>
        </Actions>
      </TableColumn>
    </TableRow>
  );

  if (isLoading) return <Load isLoading={isLoading} />;

  return (
    <React.Fragment>
      <BreadCrumb
        location={location}
        title={"Beneficiário: " + beneficios.candidatoNome}
      />
      <Body>
        <ButtonDefault
          onClick={() => redirectToContracts()}
          width="230px"
          mr="10px"
          name={"Voltar"}
        />
        <br />
        {beneficios?.valeTransporte?.length > 0 ? (
          renderFilesCandidatesTable(
            beneficios,
            userBenefitsById,
            token,
            canShowDialog,
            setShowDialogYesOrNo,
            showDialogYesOrNo,
            setshowDialogNewFileYesOrNo,
            setBeneficioParaRemocao,
            setTipoRemocao
          )
        ) : (
          <>
            <br />
            <EmptyTable>Não possui referência de vale transporte</EmptyTable>
          </>
        )}

        <br />

        {beneficios.valeRefeicao > 0 ? (
          rendertableValeRefeicao(
            beneficios,
            userBenefitsById,
            token,
            canShowDialog,
            setShowDialogYesOrNo,
            showDialogYesOrNo,
            setshowDialogNewFileYesOrNo,
            setBeneficioParaRemocao,
            setTipoRemocao
          )
        ) : (
          <EmptyTable>Não possui referência de vale refeição</EmptyTable>
        )}
        {beneficios?.valeTransporte?.length > 0 ? (
          <React.Fragment>
            <DialogEditValeTransporte
              show={showDialog}
              formValues={beneficios}
              setFormValues={userBenefitsById}
              setShowDialog={setShowDialog}
              indiceBeneficio={indiceBeneficioEditar}
              AtualizarValeTransporte={AtualizarValeTransporte}
              userId={userId}
            />
          </React.Fragment>
        ) : (
          ""
        )}
        {beneficios.valeRefeicao > 0 ? (
          <React.Fragment>
            <DialogEditValeRefeicao
              showDialogValeRefeicao={showDialogValeRefeicao}
              formValues={beneficios}
              setFormValues={userBenefitsById}
              setShowDialogValeRefeicao={setShowDialogValeRefeicao}
              indiceBeneficio={indiceBeneficioEditar}
              AtualizarValeRefeicao={AtualizarValeRefeicao}
              userId={userId}
            />
          </React.Fragment>
        ) : (
          ""
        )}
        <YesOrNoDialog
          show={showDialogYesOrNo}
          cancelAction={() => {
            setShowDialogYesOrNo(false);
          }}
          confirmAction={() => {
            if (TipoRemocao === "ValeTransporte") {
              removerValeTransporte(BeneficioParaRemocao);
              beneficios.valeTransporte = beneficios.valeTransporte.filter(
                (item) => item.id !== BeneficioParaRemocao.id
              );
            }
            if (TipoRemocao === "ValeRefeicao") {
              RemoverValeRefeicao({ BeneficioParaRemocao });
              beneficios.valeRefeicao = null;
            }
            setShowDialogYesOrNo(false);
          }}
          question={<FormattedMessage id="manager.realmenteDesejaDeletar" />}
        />
      </Body>
    </React.Fragment>
  );
};

const DialogEditValeTransporte = ({
  show,
  formValues,
  setShowDialog,
  indiceBeneficio,
  AtualizarValeTransporte,
  userId,
}) => {
  useEffect(() => {}, [formValues]);

  const closeModal = () => {
    setShowDialog(!show);
  };

  useEffect(() => {
    setBeneficio(formValues);
  }, [formValues]);

  const handleClose = () => setShowDialog(false);
  const [Beneficio, setBeneficio] = useState(formValues);

  const handleChange = (index, field, newValue) => {
    const updatedValeTransporte = [...Beneficio.valeTransporte];
    updatedValeTransporte[index] = {
      ...updatedValeTransporte[index],
      [field]: newValue,
    };
    setBeneficio({ ...Beneficio, valeTransporte: updatedValeTransporte });
  };
  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Title
          size="1.500em"
          color="title"
          title={<FormattedMessage id="contracts.sendConfirmMsgTitleBenefit" />}
        />
      </Modal.Header>
      <Modal.Body>
        <Row style={{ marginTop: "10px" }}>
          <Col
            lg={6}
            style={{
              marginTop: "10px",
              display: "flex",
              flexFlow: "column",
              padding: "15px",
            }}
          >
            <Col md={12}>
              <TextField
                label={<FormattedMessage id="Empresa Transportadora" />}
                disabled={Beneficio.valeTransporte?.length == 0}
                value={Beneficio.valeTransporte[indiceBeneficio]?.nome || ""}
                onChange={(e) => {
                  handleChange(indiceBeneficio, "nome", e.target.value);
                }}
              />
            </Col>
            <Col md={12}>
            <TextField
              label={<FormattedMessage id="Quantidade De Passagens" />}
              disabled={Beneficio.valeTransporte?.length == 0}
              value={
                Beneficio.valeTransporte[indiceBeneficio]?.quantidade || ""
              }
              onChange={(e) => {
                const numericValue = e.target.value.replace(/[^0-9]/g, "");
                handleChange(indiceBeneficio, "quantidade", numericValue);
              }}
            />
            </Col>
          </Col>
          <Col
            lg={6}
            style={{
              marginTop: "10px",
              display: "flex",
              flexDirection: "flex-start",
              alignItems: "flex-start",
              flexFlow: "column",
              padding: "15px",
            }}
          >
            <Col md={12}>
            <TextField
              label={<FormattedMessage id="Valor da Passagem" />}
              disabled={Beneficio.valeTransporte?.length == 0}
              value={Beneficio.valeTransporte[indiceBeneficio]?.valor || ""}
              onChange={(e) => {
                let money = e.target.value;
                money = money.replace(/\D/g, "");

                money = (parseFloat(money) / 100).toFixed(2).replace(".", ",");
                money = `R$ ${money}`;

                handleChange(indiceBeneficio, "valor", money);
              }}
            />

            </Col>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Col lg={{ size: 2, offset: 6 }}>
          <ButtonSecondary
            onClick={() => closeModal()}
            name={<FormattedMessage id="sharedItems.cancel" />}
          />
        </Col>
        <Col lg={{ size: 2 }}>
          <ButtonDefault
            onClick={() => {
              AtualizarValeTransporte({ Beneficio, indiceBeneficio, userId });
              closeModal();
            }}
            name={<FormattedMessage id="Atualizar" />}
          />
        </Col>
      </Modal.Footer>
    </Modal>
  );
};

const DialogEditValeRefeicao = ({
  showDialogValeRefeicao,
  formValues,
  setShowDialogValeRefeicao,
  indiceBeneficio,
  AtualizarValeRefeicao,
  userId,
}) => {
  const [Beneficio, setBeneficio] = useState(formValues);

  useEffect(() => {
    setBeneficio(formValues); // Sincroniza o estado local
  }, [formValues]);

  const closeModal = () => setShowDialogValeRefeicao(false);

  return (
    <Modal size="lg" show={showDialogValeRefeicao} onHide={closeModal}>
      <Modal.Header closeButton>
        <Title
          size="1.500em"
          color="title"
          title={<FormattedMessage id="contracts.sendConfirmMsgTitleBenefit" />}
        />
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={12}>
            <TextField
              label={<FormattedMessage id="Valor do Vale Refeição" />}
              value={Beneficio.valeRefeicao || ""}
              onChange={(e) => {
                let money = e.target.value;
                money = money.replace(/\D/g, "");
                money = (parseFloat(money) / 100).toFixed(2).replace(".", ",");
                money = `R$ ${money}`;
                setBeneficio({
                  ...Beneficio,
                  valeRefeicao: money,
                });
              }}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Col lg={{ size: 2, offset: 6 }}>
          <ButtonSecondary
            onClick={closeModal}
            name={<FormattedMessage id="sharedItems.cancel" />}
          />
        </Col>
        <Col lg={{ size: 2 }}>
          <ButtonDefault
            onClick={() => {
              AtualizarValeRefeicao({ Beneficio, indiceBeneficio, userId });
              closeModal();
            }}
            name={<FormattedMessage id="Atualizar" />}
          />
        </Col>
      </Modal.Footer>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  userBenefitsById: (payload) =>
    dispatch(userBenefits.getUserBenefitsById(payload)),

  removerValeTransporte: (payload) =>
    dispatch(userBenefits.removeValeTransporteById(payload)),

  AtualizarValeTransporte: (payload) =>
    dispatch(userBenefits.atualizarValeTransporteById(payload)),

  RemoverValeRefeicao: (payload) =>
    dispatch(userBenefits.removerValeRefeicao(payload)),

  AtualizarValeRefeicao: (payload) =>
    dispatch(userBenefits.atualizarValeRefeicaoById(payload)),

  inativarBeneficios: (payload) =>
    dispatch(userBenefits.removerBeneficioById(payload)),

  redirectToContracts: (id) => dispatch(push(`${urls.CONTRACTS_LIST}`)),

  requestAdtive: (payload) =>
    dispatch(contractsAction.adtiveTypesRequest(payload)),
});

const mapStateToProps = (state) => ({
  isLoading: state.userBenefits.isLoading,
  beneficios: state.userBenefits.beneficiosList,
  beneficioRemovido: state.userBenefits.ItemRemovido,
  beneficioAtualizado: state.userBenefits.ItemAtualizado,
  itemInativado: state.userBenefits.ItemInativado,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailBenefitsCandidate);

DetailBenefitsCandidate.propTypes = {
  filesCandidateSelected: PropTypes.func,
  beneficios: PropTypes.any,
  beneficioRemovido: PropTypes.any,
  beneficioAtualizado: PropTypes.any,
  itemInativado: PropTypes.any,
};

DetailBenefitsCandidate.defaultProps = {
  candidates: () => {},
  beneficios: () => {},
  beneficioRemovido: false,
  itemInativado: false,
  isLoading: false,
  beneficioAtualizado: false,
};
