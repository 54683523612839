import "moment/locale/pt-br";
import moment from "moment";
import { put, select, takeLatest } from "redux-saga/effects";
import * as routes from "../../utils/contants/urls";
import * as urlsApi from "../../utils/contants/urlsApi";
import API from "../../utils/API";
import { Types as MessageTypes } from "../ducks/messages";
import { Types } from "../ducks/documents";
import {
  buildPagination,
  buildErroHandleItems,
  sleep,
} from "../../helpers/sharedHelpers";
import requestAPI from "../../helpers/requestHelpers";
import { GET, POST, PUT } from "../../utils/contants/verbs";
import { history } from "../../utils/routes";

moment.locale("pt-BR");

function requestCandidadesFromVacancyList(body) {
  return API.put(`${urlsApi.REQUEST_CANDIDATESVACANCY_DOCS}`, body)
    .then((res) => res.data)
    .catch((error) => {
      throw error;
    });
}

const filterCandidates = (payload) => {
  const body = {
    nomeFuncionario: payload.nomeFuncionario,
    cpfList: payload.cpfList,
    idVagasList: payload.idVagasList,
    idLocaisList: payload.idLocaisList,
    dataInicioAtividade: payload.dateInitActivity,
    idStatusList: payload.idStatusList,
    numeroPagina: payload.NumeroPagina,
    quantidadeItens: payload.quantidadeItens,
  };

  return body;
};

function requestDownloadDocuments(id) {
  return API.post(
    `${urlsApi.DOWLOAD_DOCUMENTS_FILES}?idCandidatoEmEtapa=${id}`,
    {},
    {
      headers: {
        'Content-Type': 'application/zip',
      },
      responseType: 'arraybuffer',
    }
  )
    .then(res => {
      const blob = new Blob([res.data], { type: "application/zip" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "arquivo.zip");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch(error => { throw error });
}

function* getDowloadDocumentsFiles({ payload }) {
  try {
    const candidateDocuments = yield requestDownloadDocuments(payload.id);

    yield put({
      type: Types.DOWLOAD_DOCUMENTS_FILES_SUCCESS,
      candidateDocuments
    });
  } catch (error) {
    yield put({ type: Types.DOWLOAD_DOCUMENTS_FILES_FAIL, error });
  }
}

function* getCandidatesVacancyDocs({ payload }) {
  try {
    const {lista , paginacao} = yield requestCandidadesFromVacancyList(filterCandidates(payload));

    const candidates = {
      candidatos: lista?.candidatosNaEtapaList?.map((e) => ({
      
        cpf: e.cpf,
        idCandidatoNaVaga: e.idCandidatoNaVaga,
        idVaga: e.idVaga,
        nome: e.nome,
        statusCandidatoNaEtapa: e.statusCandidatoNaEtapa,
        tituloVaga: e.tituloVaga,
        dataUltimaAtualizacao: e.dataAprovacao,
        nomeAprovador: e.nomeAprovador
      })),
      candidatosPorVaga: lista?.candidatosNaEtapaPorVagaList?.map(e => ({
        
        idVaga: e.idVaga,
        nomeVaga: e.nomeVaga,
        candidatesByVacancyList: e.candidatosNaEtapaList?.map(c => ({
          cpf: c.cpf,
          idCandidatoNaVaga: c.idCandidatoNaVaga,
          idVaga: c.idVaga,
          nome: c.nome,
          statusCandidatoNaEtapa: c.statusCandidatoNaEtapa,
          tituloVaga: c.tituloVaga,
          dataUltimaAtualizacao: e.dataAprovacao,
          nomeAprovador: c.nomeAprovador
        }))
      }))
    };

    let candidatos;
    let candidatosPorVaga;
    const hasMore = payload.firstPage
      ? true
      : lista.candidatosNaEtapaList.length;
    const stateCandidateList = yield select(getStateCandidatesList);
    candidatos = candidates.candidatos;
    candidatosPorVaga = candidates.candidatosPorVaga;
    const AllCandidates = {
      candidatos: candidatos,
      candidatosPorVaga: candidatosPorVaga,
    };

    const paginacaoNumber = {...paginacao, page: payload.numeroPagina}

    const pagination = buildPagination(
      paginacaoNumber,
      payload,
      AllCandidates.candidatos,
      stateCandidateList,
      20,
    );

    yield put({
      type: Types.CANDIDATES_VACANCYS_DOCS_SUCCESS,
      candidates: AllCandidates,
      pagination
    });
  } catch (error) {
    yield put({ type: Types.CANDIDATES_VACANCYS_DOCS_FAIL, error });
  }
}

const getActualPage = (state) => state.documents?.page;
const getStateCandidatesList = (state) => state.documents?.candidates;

function requestCandidadeFilesList(id) {
  return API.get(`${urlsApi.REQUEST_CANDIDATES_FILES}?Id=${id}`)
    .then((res) => res.data)
    .catch((error) => {
      throw error;
    });
}

function* getCandidateFiles({ payload }) {
  try {
    const candidateDocs = yield requestCandidadeFilesList(payload.id);

    if (candidateDocs && candidateDocs.documentosEnviados.lenght == 0) {
      window.location.href = `${routes.DOCUMENTS_CANDIDATES}`;
    }

    const candidatesFiles = {
      nome: candidateDocs.nome,
      idCandidato: candidateDocs.idCandidato,
      idCandidatoEtapa: candidateDocs.idCandidatoEmEtapa,
      applyedAt: candidateDocs.dataInscricao,
      statusEtapa: candidateDocs.statusEtapa,
      podeConfirmar: candidateDocs.podeConfirmar,
      podeEditar: candidateDocs.podeEditar,
      cpf: candidateDocs.cpf,
      idVaga: candidateDocs.idVaga,
      idEtapa: candidateDocs.idEtapa,
      nomeVaga: candidateDocs.nomeVaga,

      filesList: candidateDocs.documentosEnviados
        ? candidateDocs.documentosEnviados.map((e) => ({
            Id: e.id,
            url: e.url,
            nome: e.nome,
            status: e.status,
            instrucao: e.descricao,
            type: e.tipo,
          }))
        : [],
      filesBaseList: candidateDocs.documentosPossiveisDeEnvio
        ? candidateDocs.documentosPossiveisDeEnvio.map((e) => ({
            Id: e.id,
            descricao: e.descricao,
            nome: e.nome,
            tipoDocumento: e.tipoDocumento,
          }))
        : [],
    };

    yield put({
      type: Types.CANDIDATE_FILES_SUCCESS,
      candidatesFiles,
    });
  } catch (error) {
    yield put({ type: Types.CANDIDATE_FILES_FAIL, error });
  }
}

function requestSetStatusFile(payload) {
  return API.put(`${urlsApi.SET_CANDIDATES_FILES}`, {
    IdDocumento: payload.idDocumento,
    IdCandidatoEmEtapa: payload.idCandidatoEmEtapa,
    Status: parseInt(payload.status),
    messageParaCandidato: payload.message,
  })
    .then((res) => res.data)
    .catch((error) => {
      throw error;
    });
}

function* setStatusFiles({ payload }) {
  try {
    const candidateDocs = yield requestSetStatusFile(payload);

    window.location.href = `${routes.DOCUMENTS_LIST}/${payload.idCandidatoEmEtapa}`;
  } catch (error) {
    yield put({ type: Types.CANDIDATE_SET_STATUS_FILE_FAIL, error });
  }
}

function requestSetStatusStage(payload) {
  return API.get(
    `${urlsApi.DOCUMENTS_CONFIRM_STAGE}?idCandidatoEtapa=${payload.id}`
  )
    .then((res) => res.data)
    .catch((error) => {
      throw error;
    });
}

function* setStatusStage({ payload }) {
  try {
    const candidateDocs = yield requestSetStatusStage(payload);

    window.location.href = `${routes.DOCUMENTS_LIST}/${payload.id}`;
    window.location.href = `${routes.DOUBTS_LIST}/${payload.id}`;

  } catch (error) {
    yield put({ type: Types.CANDIDATE_SET_STATUS_STAGE_FAIL, error });
  }
}

function requestNewDocToCandidate(payload) {
  return API.put(`${urlsApi.SET_DOCUMENT_CANDIDATE}`, {
    IdDocumento: parseInt(payload.idDocumentoBase),
    IdCandidatoEmEtapa: payload.idCandidatoEmEtapa,
    messageParaCandidato: payload.message,
  })
    .then((res) => res.data)
    .catch((error) => {
      throw error;
    });
}

function* setNewDocToCandidate({ payload }) {
  try {
    yield requestNewDocToCandidate(payload);
    window.location.href = `${routes.DOCUMENTS_LIST}/${payload.idCandidatoEmEtapa}`
    yield put({
      type: Types.CANDIDATE_FILES_SUCCESS,
    });
  } catch (error) {
    yield put({ type: Types.CANDIDATE_FILES_FAIL, error });
  }
}

function* setNewDocToSign({ payload }) {
  try {
    const formData = new FormData();

    formData.append("arquivo", payload.file);
    formData.append("idDocumento", payload.idDocumento);
    formData.append("idCandidato", payload.idCandidato);
    formData.append("mesReferencia", payload.mesReferencia);
    formData.append("deltaX", payload.delta.x);
    formData.append("deltaY", payload.delta.y);
    formData.append("pagina", payload.pagina);
    
    yield requestAPI({
      verb: PUT,
      endPoint: urlsApi.SET_DOCUMENT_TO_SIGN,
      data: formData,
    });

    yield put({
      type: Types.CANDIDATE_INCLUDE_NEW_DOC_TO_SIGN_SUCCESS,
    });

    const messages = [{ type: "info", message: "Sucesso, o documento foi enviado para assinatura." }];
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION_SUCCESS, messages });
    
  } catch (error) {
    yield put({ type: Types.DOUBTS_LIST_FAIL, error });
    const messages = buildErroHandleItems(error.response);
    yield put({ type: MessageTypes.MESSAGE_PUBLICATION, messages });
    yield put({ type: Types.CANDIDATE_INCLUDE_NEW_DOC_TO_SIGN_FAIL, error });
        
  } finally {
    sleep(5000).then(() => {
      history.push(`${routes.ENVIAR_DOCUMENTS_CANDIDATES}`);
    });
  }
}

export function* watcherSaga() {
  yield takeLatest(
    Types.CANDIDATES_VACANCYS_DOCS_REQUEST,
    getCandidatesVacancyDocs
  );
  yield takeLatest(Types.CANDIDATE_FILES_REQUEST, getCandidateFiles);
  yield takeLatest(Types.DOWLOAD_DOCUMENTS_FILES_REQUEST, getDowloadDocumentsFiles);
  yield takeLatest(Types.CANDIDATE_SET_STATUS_FILE_REQUEST, setStatusFiles);

  yield takeLatest(Types.CANDIDATE_SET_STATUS_STAGE_REQUEST, setStatusStage);

  yield takeLatest(Types.CANDIDATE_INCLUDE_NEW_DOC_REQUEST, setNewDocToCandidate);

  yield takeLatest(Types.CANDIDATE_INCLUDE_NEW_DOC_TO_SIGN_REQUEST, setNewDocToSign);
}
