// **PRODUCAO */
export const basePath = "https://chalezinhoapi.fikdigital.com.br:9002";

// *DEVENVOLVIMENTO */
// export const basePath = "http://localhost:5001";


const service = "api";
const version = "v1";
export const vacancyApi = `${basePath}/${service}/${version}/Vaga/Filtrar`;
export const vacancyShowApi = `${basePath}/${service}/${version}/Vaga`;
export const vacancySearchApi = `${basePath}/${service}/${version}/Vaga/Buscar`;
export const PUBLISH_VACANCY = id => `${basePath}/${service}/${version}/Vaga/Publicar/${id}`;
export const DELETE_VACANCY = `${basePath}/${service}/${version}/Vaga`;

export const roleListApi = `${basePath}/${service}/${version}/Cargo/ListarTodosComPaginacao`;

export const SEARCH_ADDRESS_BY_ZIP_CODE = `https://viacep.com.br/ws/`;


export const FORM_INPUTS_URLS = {
  countries: `${basePath}/${service}/${version}/Pais`,
  states: `${basePath}/${service}/${version}/Estado`,
  cities: `${basePath}/${service}/${version}/Cidade`,
  citiesFromState: `${basePath}/${service}/${version}/Cidade/ListarTodosDoEstado`,
  statesFromCountry: `${basePath}/${service}/${version}/Estado/ListarTodosDoPais`,
  workplaces: `${basePath}/${service}/${version}/Cidade/ListarTodasAsCidadesDeLocaisDeTrabalho`,
  workplacesLocation: `${basePath}/${service}/${version}/Cidade/ListarLocaisDeTrabalho`,
  addressCountries: `${basePath}/${service}/${version}/Pais`,
  addressStates: `${basePath}/${service}/${version}/Estado`,
  addressCities: `${basePath}/${service}/${version}/Cidade`,
  issuingStateRG: `${basePath}/${service}/${version}/Estado`
};

export const dashboardUrls = {
  vacancy: `${basePath}/${service}/${version}/Dashboard/Vagas`,
  candidates: `${basePath}/${service}/${version}/Dashboard/Candidatos`,
  talents: `${basePath}/${service}/${version}/Dashboard/BancoTalentos`,
  documents: `${basePath}/${service}/${version}/Dashboard/Documento`,
  contracts: `${basePath}/${service}/${version}/Dashboard/Contratos`,
}

export const formInputFilliate = `${basePath}/${service}/${version}/CentroResultado/ListarTodosDaColigada`;
export const formInputAutoComplete = `${basePath}/${service}/${version}/funcionario/FiltrarFuncionarioPorNomeOuMatricula`;

export const GET_USER_INFORMATION = `${basePath}/${service}/${version}/candidato/RecuperarInformacoesSimplicadas`;

export const UPDATE_PERSONAL_INFORMATION = `${basePath}/${service}/${version}/candidato/AtualizarInformacoesPessoais`;

export const login = `${basePath}/${service}/${version}/Login`;
// inputs end points
export const formInputsUrls = {
  areaFormation: `${basePath}/${service}/${version}/AreaFormacao`,
  eligibleJobs: `${basePath}/${service}/${version}/Cargo`,
  resultCenter: `${basePath}/${service}/${version}/CentroResultado`,
  city: `${basePath}/${service}/${version}/Cidade/ListarCidadesDasUnidadesDeTrabalho`,
  affiliate: `${basePath}/${service}/${version}/Coligada`,
  tecnicKnowledge: `${basePath}/${service}/${version}/ConhecimentoTecnico`,
  state: `${basePath}/${service}/${version}/Estado`,
  employee: `${basePath}/${service}/${version}/Funcionario`,
  jorney: `${basePath}/${service}/${version}/Jornada`,
  placeOfWork: `${basePath}/${service}/${version}/LocalUnidade/ListarTodosTipoLocalTrabalho`,
  level: `${basePath}/${service}/${version}/Nivel`,
  country: `${basePath}/${service}/${version}/Pais`,
  section: `${basePath}/${service}/${version}/Secao`,
  taker: `${basePath}/${service}/${version}/Tomador`,
  area: `${basePath}/${service}/${version}/Area`,
  schooling: `${basePath}/${service}/${version}/Vaga/InformacoesParaTela`,
  managerName: `${basePath}/${service}/${version}/Funcionario`,
  placeOfMedicExame: `${basePath}/${service}/${version}/LocalUnidade/ListarTodosTipoLocalExameMedico`,
  vacancyGroup: `${basePath}/${service}/${version}/GrupoVaga`,
  administrator: `${basePath}/${service}/${version}/Administrador/ListarTodos`,
};

export const stageCreateApi = `${basePath}/${service}/${version}/Etapa`;
export const getStageShowApi = `${basePath}/${service}/${version}/Etapa/RecuperarEtapaPorId`;
export const getStagesFilter = `${basePath}/${service}/${version}/Etapa/RecuperarEtapasParaAssociarTrilha`;
export const FINISH_ESTAGE_TO_USE = `${basePath}/${service}/${version}/Etapa/FinalizarCadastroEtapa`;
export const DELETE_STAGE_QUESTION_BLOCK = `${basePath}/${service}/${version}/Etapa/DeletarPerguntasDoTipoBloco`;

export const PERFORMACE_INDICATOR = `${basePath}/${service}/${version}/IndicadorPerformance`;

export const CREATE_RAILS = `${basePath}/${service}/${version}/Trilha`;
export const EDIT_RAILS = `${basePath}/${service}/${version}/Trilha/Editar`;
export const ADD_STAGE_TO_RAILS = `${basePath}/${service}/${version}/Trilha/AdicionarEtapa`;
export const GET_LIST_RAILS = `${basePath}/${service}/${version}/Trilha/RecuperarTodasSimplificadas`;
export const SHOW_LIST_RAILS = `${basePath}/${service}/${version}/Trilha/RecuperarPorId`;
export const GET_RAILS_TO_ASSOCIATE = `${basePath}/${service}/${version}/Trilha/RecuperarParaAssociarComVaga`;
export const DISABLE_RAILS = `${basePath}/${service}/${version}/Trilha/AlterarStatusTrilha`;
export const REMOVE_STAGE_FROM_RAILS = `${basePath}/${service}/${version}/trilha/RemoverEtapaTrilha`;
export const REQUEST_JOBS_FROM_AREA = `${basePath}/${service}/${version}/cargo/ListarTodosDaArea`;
export const REQUEST_ASSOCIATION_WITH_RAILS = `${basePath}/${service}/${version}/vaga/AssociarComTrilha`;
export const questionCreateApi = `${basePath}/${service}/${version}/Etapa/AdicionarPergunta`;
export const questionRegisterPositionToStage = `${basePath}/${service}/${version}/Etapa/AssociarCargos`;
export const questionRegisterToStage = `${basePath}/${service}/${version}/Etapa/AdicionarPergunta`;
export const QUESTION_UPDATE = `${basePath}/${service}/${version}/Etapa/EditarPergunta`;
export const QUESTION_DELETE = `${basePath}/${service}/${version}/Etapa/DeletarPergunta`;
export const EDIT_ASSOCIATION_ATTRIBUTES = `${basePath}/${service}/${version}/vaga/EditarAssociacaoTrilhaVaga`;
export const REQUEST_CANDIDATES_BANK_OF_TALENTS = `${basePath}/${service}/${version}/BancoTalentos/BuscarCandidatos`;
export const REQUEST_CHANGE_STATUS_CANDIDATES_BANK_OF_TALENTS = `${basePath}/${service}/${version}/BancoTalentos/AlterarStatus`;
export const ADMISSION_CANDIDATES_INFORMATION = id => `${basePath}/${service}/${version}/candidato/AdmissaoCandidato/${id}`;

export const stageListUrls = {
  baseInfos: `${basePath}/${service}/${version}/Etapa/RecuperarEtapasDadosCadastrais`,
  tests: `${basePath}/${service}/${version}/Etapa/RecuperarEtapasTestes`,
  trainner: `${basePath}/${service}/${version}/Etapa/RecuperarEtapasTreinamento`,
  assay: `${basePath}/${service}/${version}/Etapa/RecuperarEtapasAnalise`,
  medicExame: `${basePath}/${service}/${version}/Etapa/RecuperarEtapasExameMedico`,
  admission: `${basePath}/${service}/${version}/Etapa/RecuperarEtapasAdmissao`,
  feedback: `${basePath}/${service}/${version}/Etapa/RecuperarEtapasFeedback`,
  ambientation: `${basePath}/${service}/${version}/Etapa/RecuperarEtapasAmbientacao`,
  preAdmition: `${basePath}/${service}/${version}/Etapa/RecuperarEtapasPreAdmissao`
};

export const stageCreateInfosUrls = {
  testeInfo: `${basePath}/${service}/${version}/Etapa/InformacoesParaTelaEtapaTeste`,
  assayInfo: `${basePath}/${service}/${version}/Etapa/InformacoesParaTelaEtapaAnalise`,
  ambientationInfo: `${basePath}/${service}/${version}/Etapa/InformacoesParaTelaEtapaAmbientacao`,
  medicExameInfo: `${basePath}/${service}/${version}/Etapa/InformacoesParaTelaEtapaExameMedico`,
  trainnerInfo: `${basePath}/${service}/${version}/Etapa/InformacoesParaTelaEtapaTreinamento`
};

export const VANCANCY_CANDIDATES = `${basePath}/${service}/${version}/GerenciarCandidatos/ListarTodasVagasSimplificadas`;
export const CANDIDATES_FROM_VANCANCY = `${basePath}/${service}/${version}/GerenciarCandidatos/RecuperarVagaPorId`;
export const CANDIDATES_BY_NAME = `${basePath}/${service}/${version}/Candidato/FiltrarPorNome`;
export const CANDIDATES_ACTION = `${basePath}/${service}/${version}/GerenciarCandidatos/ExecutarAcaoNosCandidatos`;
export const CANDIDATE_SHOW = `${basePath}/${service}/${version}/GerenciarCandidatos/RecuperarCandidatoNaVagaPorIds`;
export const EXPORT_CANDIDATES = `${basePath}/${service}/${version}/GerenciarCandidatos/ExportarCandidatos`;
export const EMPLOYEE_BY_REGISTER_NUMBER = `${basePath}/${service}/${version}/Funcionario/RecuperarPorMatricula`;
export const ADD_CANDIDATE = `${basePath}/${service}/${version}/Vaga/AdicionarCandidatoNaVaga`;
export const CREATE_CANDIDATE = `${basePath}/${service}/${version}/GerenciarCandidatos/CadastrarCandidato`;
export const CREATE_CANDIDATE_BULK = `${basePath}/${service}/${version}/GerenciarCandidatos/CadastrarCandidatoEmMassa`;

export const CANCELAR_VAGA = `${basePath}/${service}/${version}/Vaga/CancelarVaga`;


export const REQUEST_PROFILES = `${basePath}/${service}/${version}/PerfilAcesso`;
export const REQUEST_PROFILEPERMISSIONS = `${basePath}/${service}/${version}/PerfilAcesso/ListaPermissoesDoPerfil`;
export const REQUEST_USERS = `${basePath}/${service}/${version}/PerfilAcesso/ListaUsuariosAdminAtivos`;
export const UPDATE_PERFIL_USERS = `${basePath}/${service}/${version}/PerfilAcesso/EditaPerfilUsuario`;
export const CREATE_PROFILES = `${basePath}/${service}/${version}/PerfilAcesso/CriarPerfil`;

export const EDIT_PERMISSIONS_PROFILES = `${basePath}/${service}/${version}/PerfilAcesso/EditaPermissoesDoPerfil`;

export const EDIT_ACCESS_STAGE_PROFILES = `${basePath}/${service}/${version}/PerfilAcesso/EditaAcessoEtapasPerfil`;




export const BANNER_ASSOCIATION = `${basePath}/${service}/${version}/Vaga/AssociarBannerAVaga`;
export const VANCANCY_PREVIEW_REQUEST = id =>
  `${basePath}/${service}/${version}/Vaga/VagaParaPreview/${id}`;


// PLUG --------------------------------------------------------------------------------------------------------
export const REQUEST_CANDIDATESVACANCY_DOCS = `${basePath}/${service}/${version}/Documentos/BuscarCandidatosDaVaga`;
export const REQUEST_CANDIDATES_FILES = `${basePath}/${service}/${version}/Documentos/RecuperaArquivosCandidatoEtapa`;
export const SET_CANDIDATES_FILES = `${basePath}/${service}/${version}/Documentos/AlteraStatusDocumento`;
export const DOCUMENTS_CONFIRM_STAGE = `${basePath}/${service}/${version}/Documentos/ConfirmaEtapaEnvioDocumentos`;
export const DOWLOAD_DOCUMENTS_FILES = `${basePath}/${service}/${version}/Documentos/ExportarDocumentoCandidato`;
export const SET_DOCUMENT_CANDIDATE = `${basePath}/${service}/${version}/Documentos/RegistraNovoDocumentoCandidato`;
export const SET_DOCUMENT_TO_SIGN = `${basePath}/${service}/${version}/Documentos/RegistrarNovoDocumentoAssinatura`;

export const GET_PERSONAL_INFORMATION = id => `${basePath}/${service}/${version}/Candidato/RecuperarInformacoesCandidato/${id}`;
export const PLUG_GET_CANDIDATES_LIST = `${basePath}/${service}/${version}/Candidato/ListarCandidatos`;
export const RELEASE_PASSWORD_RESET = `${basePath}/${service}/${version}/Candidato/ResetarSenhaCandidato`;

export const RELEASE_CANCELLATION_FOR_CANDIDATES = `${basePath}/${service}/${version}/Candidato/CancelarEtapaDoCandidato`;

export const REQUEST_CANDIDATES_VACANCY_CONTRACT = `${basePath}/${service}/${version}/Contrato/BuscarCandidatosDaVaga`;
export const REQUEST_TYPES_CONTRACT = `${basePath}/${service}/${version}/Contrato/BuscarTiposOpcaoContrato`;
export const REQUEST_CANDIDATES_CONTRACT_FILES = `${basePath}/${service}/${version}/Contrato/RecuperaContratosCandidatoEtapa`;
export const REQUEST_CANDIDATE_DOWNLOAD_CONTRACT_FILES = `${basePath}/${service}/${version}/Contrato/ExportarContratoCandidato`;
export const REQUEST_ADTIVE_TYPES = `${basePath}/${service}/${version}/Contrato/RecuperarTiposAditivo`;
export const SET_CANDIDATES_CONTRACT_STATUS = `${basePath}/${service}/${version}/Contrato/AlteraStatusContrato`;
export const SET_CANDIDATES_NEWCONTRACT = `${basePath}/${service}/${version}/Contrato/AlteraStatusContrato`;
export const CONTRACT_CONFIRM_STAGE = `${basePath}/${service}/${version}/Contrato/ConfirmaEtapaEnvioContrato`;
export const CHANCE_CONTRACT_WITH_ERROR = `${basePath}/${service}/${version}/Contrato/AlteraStatusContratoComAlteracaoContrato`;
export const REQUEST_USERS_ADMIN = `${basePath}/${service}/${version}/Contrato/BuscarUsuariosAdmin`;

export const GET_TOMADOR_RM = coligada => `${basePath}/${service}/${version}/Tomador/TomadorPorColigada/${coligada}`;

export const GET_TIPO_FUNCIONARIO_RM = `${basePath}/${service}/${version}/TipoFuncionario`;
export const GET_SECAO_RM = coligada => `${basePath}/${service}/${version}/Secao/SecaoPorColigada/${coligada}`;
export const GET_SECAO_PERFIL_RM = `${basePath}/${service}/${version}/Secao/SecaoPorPerfil`;
export const GET_PROJETO_ADMIN = `${basePath}/${service}/${version}/Projeto/ProjetosPorAdmin`;

export const GET_CANDIDATO_SECAO_PERFIL_RM = coligada => `${basePath}/${service}/${version}/Secao/SecaoPorPerfilCandidato/${coligada}`;
export const GET_PROJETO_CANDIDATO = coligada => `${basePath}/${service}/${version}/Projeto/ProjetosPorCandidatos/${coligada}`;

export const GET_FUNCAO_RM = `${basePath}/${service}/${version}/Funcao/FuncaoListaTodos`;

export const GET_COLIGADA_RM = `${basePath}/${service}/${version}/coligada`;
export const GET_COLIGADA_RM_PERFIL = `${basePath}/${service}/${version}/coligada/ListarColigadasCriacaoUsuario`;


export const GET_FUNCAO_BY_COLIGADA_RM = coligada => `${basePath}/${service}/${version}/Funcao/FuncaoPorColigada/${coligada}`;
export const GET_MOTIVO_ADMISSAO_BY_COLIGADA_RM = coligada => `${basePath}/${service}/${version}/MotivoAdmissao/MotivoAdmissaoPorColigada/${coligada}`;
export const GET_SINDICATO_BY_COLIGADA_RM = coligada => `${basePath}/${service}/${version}/SindicatoVinciRM/SindicatoPorColigada/${coligada}`;
export const GET_HORARIO_BY_COLIGADA_RM = coligada => `${basePath}/${service}/${version}/HorarioVinciRM/HorarioPorColigada/${coligada}`;
export const GET_DENTAL_PLAN_BY_COLIGADA_RM = coligada => `${basePath}/${service}/${version}/PlanoDentalRM/PlanoDentalPorColigada/${coligada}`;
export const GET_SEGURO_BY_COLIGADA_RM = coligada => `${basePath}/${service}/${version}/SeguroDeVidaRM/SeguroDeVidaPorColigada/${coligada}`;
export const GET_MEDIC_ASSIST_BY_COLIGADA_RM = coligada => `${basePath}/${service}/${version}/AssistenciaMedicaRM/AssistenciaMedicaPorColigada/${coligada}`;
export const GET_MEDIC_ASSIST_BRADESCO_BY_COLIGADA_RM = coligada => `${basePath}/${service}/${version}/AssistenciaMedicaBradescoRM/AssistenciaMedicaBradescoPorColigada/${coligada}`;
export const GET_MEDIC_ASSIST_UNIMED_BY_COLIGADA_RM = coligada => `${basePath}/${service}/${version}/AssistenciaMedicaUnimedRM/AssistenciaMedicaUnimedPorColigada/${coligada}`;
export const GET_DENTAL_PLAN_SULAMERICA_BY_COLIGADA_RM = coligada => `${basePath}/${service}/${version}/PlanoDentalSulamericaRM/PlanoDentalSulamericaPorColigada/${coligada}`;
export const GET_ALIMENTACAO_BY_COLIGADA_RM = coligada => `${basePath}/${service}/${version}/AlimentacaoVinciRM/AlimentacaoRMPorColigada/${coligada}`;
export const GET_REFEICAO_BY_COLIGADA_RM = coligada => `${basePath}/${service}/${version}/RefeicaoVinciRM/RefeicaoRMPorColigada/${coligada}`;
export const GET_HIERARQUIA_CADASTRO_RM = `${basePath}/${service}/${version}/HierarquiaCadastroFluigRM/HierarquiaCadastroFluigRMListarTodos`;

export const SET_NEW_CONTRACT = `${basePath}/${service}/${version}/Contrato/RegistraNovoContratoCandidato`;
export const CHANGE_CONTRACT = `${basePath}/${service}/${version}/Contrato/AtualizarContrato`;
export const CHANGE_KIT_CONTRACT = `${basePath}/${service}/${version}/Contrato/AlterarKit`;


// MANAGER
export const CREATE_MANAGER = `${basePath}/${service}/${version}/Administrador/`;
export const CHANGE_PASSWORD_MANAGER = `${basePath}/${service}/${version}/Administrador/AlterarSenha`;
export const CHANGE_ACESS_PLUG_MANAGER = `${basePath}/${service}/${version}/Administrador/AlteraPodeAcessarPlug`;
export const CHANGE_ACESS_TALENTOS_MANAGER = `${basePath}/${service}/${version}/Administrador/AlteraPodeAcessarTalentos`;
export const CHANGE_ACESS_USER_MANAGER = `${basePath}/${service}/${version}/Administrador/AlteraPodeEditarPerfil`;
export const DELETE_MANAGER = `${basePath}/${service}/${version}/Administrador/Deletar`;
export const CHANGE_USER = `${basePath}/${service}/${version}/Administrador/AlterarPermissoesAdministrativo`;
export const REQUEST_USERS_ID = `${basePath}/${service}/${version}/Administrador/ListarPorId`;

// courses and formations
export const GET_EDUCATION_LEVEL = `${basePath}/${service}/${version}/candidato/RecuperarEscolaridades`;


// Tipo de bairro
export const GET_ALL_TYPE_NEIGHBORHOOD = `${basePath}/${service}/${version}/TipoBairro`;

// Tipo de logradouro
export const GET_ALL_TYPE_STREET = `${basePath}/${service}/${version}/TipoLogradouro`;

// Disponibilidade de trabalho
export const GET_ALL_TYPE_DAY_AVAILABILITY = `${basePath}/${service}/${version}/DisponibilidadeDiasTrabalho`;

// Disponibilidade de Horario
export const GET_ALL_TYPE_TIME_AVAILABILITY = `${basePath}/${service}/${version}/DisponibilidadeHorarioTrabalho`;

//Assinatura Adm
export const LIST_DATA_SIGNATURES = `${basePath}/${service}/${version}/AssinaturaAdministradorContratos/ListaDadosAssinate`;
export const PLUG_ASSINATURA_PENDENTE_LIST = `${basePath}/${service}/${version}/AssinaturaAdministradorContratos/ListarCandidatosPendentesAssinaturaAdm`;
export const FINISH_STAGE_DIGITAL_DOCUMENT_API = `${basePath}/${service}/${version}/AssinaturaAdministradorContratos/FinalizarEnvioContrato`;
export const GET_CONTRACT_DOCUMENT = `${basePath}/${service}/${version}/AssinaturaAdministradorContratos/ObtemContratoOuTermoAssinado`;
export const GET_DEPENDENT_DATA = `${basePath}/${service}/${version}/DocumentoContrato/ObtemDadosDependentes`;
export const GET_HEALTH_AND_DENTAL_PLAN_VALUES = `${basePath}/${service}/${version}/DocumentoContrato/ObtemValoresPlanoSaudeOdontologico`;
export const UPLOAD_CONTRACT_DOCUMENT = `${basePath}/${service}/${version}/DocumentoContrato/AtualizarDocumento`;


//TEST TRAILS
export const INITIAL_TEST = `${basePath}/${service}/${version}/Etapa/IniciarTeste/`;
export const RESPONSE_REGISTRATION_DATA = `${basePath}/${service}/${version}/Etapa/ResponderPerguntaEtapaDadosCadastrais/`;
export const FINISH_STAGE_REGISTRATION_DATA = `${basePath}/${service}/${version}/Etapa/FinalizarPerguntasEtapaDadosCadastrais/`;
export const FINISH_STAGE_UPLOAD_VACANCY = `${basePath}/${service}/${version}/Etapa/ConcluirEtapaEnvioDocumentos`;
export const CHANGE_WATCH_VIDEO = `${basePath}/${service}/${version}/Etapa/MudaStatusVisualizouVideoOrientacao`;

//REDACAO
export const GIF_TRACADO_TEXTO_REDACAO = `${basePath}/redacao/bar.gif`;

//SECAO ALL
export const SECTION_LIST_ALL = `${basePath}/${service}/${version}/Secao/SecaoListarTodos`;


//requests para duvidas
export const REQUEST_CANDIDATES_DOUBTS = `${basePath}/${service}/${version}/Solicitacao/ListarDuvidasCandidato`;
export const REQUEST_CANDIDATES_DOUBTS_MANAGER = `${basePath}/${service}/${version}/Solicitacao/ListarDuvidasCandidatoGestor`;
export const REQUEST_CANDIDATES_ANSWERS = `${basePath}/${service}/${version}/Solicitacao/CriarSolicitacao`;
export const REQUEST_CHANGE_DOUBTS_STATUS = `${basePath}/${service}/${version}/Solicitacao/EncerrarSolicitacao`

export const REQUEST_CANDIDATESVACANCY_UPLOAD = `${basePath}/${service}/${version}/Upload/BuscarCandidatosParaUpload`;
export const REQUEST_CANDIDATES_ADM_FILES = `${basePath}/${service}/${version}/Documentos/RecuperaArquivosAdmEtapaRh`;
export const UPLOAD_DOCUMENT = `${basePath}/${service}/${version}/Upload/AtualizarDocumento`;


export const GET_USER_BENEFITS = `${basePath}/${service}/${version}/BeneficioCandidato/ListarBeneficios`;
export const GET_USER_BENEFITS_FILTERED = `${basePath}/${service}/${version}/BeneficioCandidato/FiltrarBeneficios`;
export const CREATE_USER_BENEFITS = `${basePath}/${service}/${version}/BeneficioCandidato/CadastrarBeneficios`;
export const GET_USER_BENEFITS_BY_ID = `${basePath}/${service}/${version}/BeneficioCandidato/GetBeneficiosPorId`;
export const CREATE_BENEFITS_BULK = `${basePath}/${service}/${version}/BeneficioCandidato/CadastrarBeneficioEmMassa`;

export const REMOVE_VALE_TRANSPORTE_BY_ID = `${basePath}/${service}/${version}/BeneficioCandidato/InativarValeTransporte`;
export const REMOVE_USER_BENEFITS_BY_ID = `${basePath}/${service}/${version}/BeneficioCandidato/InativarBeneficios`;

export const ATUALIZA_VALE_REFEICAO = `${basePath}/${service}/${version}/BeneficioCandidato/AtualizarValeRefeicao`;
export const ATUALIZA_VALE_TRANSPORTE = `${basePath}/${service}/${version}/BeneficioCandidato/AtualizarValeTransporte`;

export const GET_USER_PAYMENTS = `${basePath}/${service}/${version}/DemaisPagamentos/ListarPagamentos`;
export const GET_USER_PAYMENTS_FILTERED_BY_ID = `${basePath}/${service}/${version}/DemaisPagamentos/FiltrarPagamentoPorId`;
export const UPDATE_USER_PAYMENTS_BY_ID = `${basePath}/${service}/${version}/DemaisPagamentos/EditarPagamento`;
export const REMOVE_USER_PAYMENTS_BY_ID = `${basePath}/${service}/${version}/DemaisPagamentos/InativarPagamento`;
export const CREATE_USER_PAYMENTS = `${basePath}/${service}/${version}/DemaisPagamentos/CadastrarPagamento`;
export const GET_USER_PAYMENTS_FILTERED = `${basePath}/${service}/${version}/DemaisPagamentos/FiltrarPagamento`;
export const REMOVER_VALE_REFEICAO = `${basePath}/${service}/${version}/BeneficioCandidato/RemoveValeRefeicao`;
export const EXPORT_PAYMENTS_INFORMATION = `${basePath}/${service}/${version}/DemaisPagamentos/ExportarDadosPagamentosCSV`;

export const EXPORT_BENEFITS_INFORMATION = `${basePath}/${service}/${version}/BeneficioCandidato/ExportarDadosBneficiosCSV`;

export const ENVIAR_MENSAGEM_SOLICITACAO = `${basePath}/${service}/${version}/Solicitacao/EnviaMensagem`;
export const ENVIAR_DOCUMENTO_SOLICITACAO = `${basePath}/${service}/${version}/Solicitacao/EnviarDocumento`;
export const SEARCH_USUARIO_SOLICITACAO = `${basePath}/${service}/${version}/Administrador/FiltrarCandidato`;
