import Draggable from "react-draggable";
import { FaCheck, FaTimes } from "react-icons/fa";
import theme from "../shared/Theme";
import React, { useState, useEffect, useRef } from "react";

export default function DraggableText({ onEnd, onSet, onCancel, initialText }) {
  const [text, setText] = useState("Text");
  const inputRef = useRef(null);

  useEffect(() => {
    if (initialText) {
      setText(initialText)
    } else {
      inputRef.current.focus();
      inputRef.current.select()
    }
  }, [])

  const styles = {
    container: {
      position: "absolute",
      zIndex: 100000,
      border: `2px solid ${theme.colors.defaultColor}`,
    },
    controls: {
      position: "absolute",
      right: 0,
      display: "inline-block",
      backgroundColor: theme.colors.defaultColor,
      // borderRadius: 4,
    },
    smallButton: {
      display: "inline-block",
      cursor: "pointer",
      padding: 4,
    },
    input: {
      border: 0,
      fontSize: 20,
      padding: 3,
      backgroundColor: 'rgba(0,0,0,0)',
      cursor: 'move'
    }
  };
  return (
    <Draggable onStop={onEnd}>
      <div style={styles.container}>
        <div style={styles.controls}>
          <div style={styles.smallButton} onClick={() => onSet(text)}>
            <FaCheck color={theme.colors.softGreen} />
          </div>
          <div style={styles.smallButton} onClick={onCancel}>
            <FaTimes color={theme.colors.errorColor} />
          </div>
        </div>
        <input
          ref={inputRef}
          style={styles.input}
          value={text}
          placeholder={'Text'}
          onChange={(e) => setText(e.target.value)}
        />
      </div>
    </Draggable>
  );
}
